import { getReports } from '../services/reportsService';

const INITIAL_STATE = {
  reports: [],
  filters: {
    name: '',
    type: '',
  },
  currentPage: 1,
  pages: 1,
  fetching: false,
};

function toCamelCase(report) {
  let conciliationResults = null;

  if (report.status !== 0 && report['results']) {
    conciliationResults = {
      processedLines: report['results']['processed_lines'],
      scannings: report['results']['scannings_summary'].map((scanning) => {
        return {
          scanningNumber: scanning['scanning_number'],
          reconciledLines: scanning['reconciled_lines'],
        };
      }),
    };
  }

  return {
    id: report.id,
    name: report.name,
    status: report.status,
    errorCode: report['error_code'],
    creationDate: report['creation_date'],
    lastDownloadAt: report['last_download_at'],
    conciliationResults,
  };
}

export function fetchReports() {
  return (dispatch, getState) => {
    dispatch({ type: 'REPORTS_REQUEST' });
    const { filters, currentPage } = getState().reports;

    return getReports(currentPage, filters).then((res) => {
      const { reports, pages } = res.data;
      //TODO we need the total pages from the API
      dispatch({
        type: 'REPORTS_FETCHED',
        reports: reports.map(toCamelCase),
        pages,
      });
    });
  };
}

const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return { ...state, currentPage: action.page };
    case 'SET_FILTERS':
      return { ...state, filters: action.filters };
    case 'REPORTS_REQUEST':
      return { ...state, fetching: true };
    case 'REPORTS_FETCHED':
      return {
        ...state,
        reports: action.reports,
        fetching: false,
        pages: action.pages,
      };
    default:
      return state;
  }
};

export default reportsReducer;
