import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

const Label = styled.label`
  font-size: 13px;
  color: #a3a3a3;
  cursor: pointer;

  & div.switch {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 14px;
    outline: none;
    top: 2px;
    margin-right: 8px;
  }

  & input {
    position: absolute;
    left: -99999px;
  }

  & .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    border-radius: 34px;
    background-color: white;
    border: 1px solid #bcc1d0;
  }
  & .slider:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: 1px;
    bottom: 1px;
    background-color: #bcc1d0;
    transition: 0.4s;
    border-radius: 50%;
  }

  & input:checked + .slider:before {
    transform: translateX(10px);
    background-color: #00dd00;
  }
`;

function Toggle({ className, checked, onChange, children }) {
  return (
    <Label className={className}>
      <div className="switch">
        <input
          checked={checked}
          onChange={onChange.bind(this, !checked)}
          type="checkbox"
        />
        <div className="slider" />
      </div>
      {children}
    </Label>
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.string,
  onChange: PropTypes.func,
};

export default Toggle;
