import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from './NumberInput';

const StyledNumberInput = styled(NumberInput)`
  :before {
    content: '$';
    position: absolute;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #243568;
    left: 6px;
    top: 4px;
  }
  .number-input {
    padding-left: 16px;
  }
`;

function MoneyInput({ disabled, className, value, onChange, min }) {
  return (
    <StyledNumberInput
      className={className}
      disabled={disabled}
      min={min}
      onChange={onChange}
      step={0.01}
      value={value}
    />
  );
}

MoneyInput.propTypes = {
  disabled: PropTypes.bool,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default MoneyInput;
