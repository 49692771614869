import React from 'react';
import ReactDOM from 'react-dom';

import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import App from './App';
import rootReducer from './reducers';

import './index.css';
import thunkMiddleware from 'redux-thunk';

import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

import { PopupboxManager } from 'react-popupbox';
import ErrorPopup from './components/common/ErrorPopup';
import { getUrlParams } from './helpers';
import { setCredentials } from './services/authenticationService';

const moment = extendMoment(originalMoment);
require('moment/locale/es');
moment.locale('es');

const params = getUrlParams();
if (params['auth_token']) {
  setCredentials({
    Authorization: params['auth_token'],
    'X-Masqueraded': params['masquerade_token'],
  });
}

const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const crashReporter = () => (next) => (action) => {
  try {
    const result = next(action);

    if (result.catch) {
      result.catch((err) => {
        setTimeout(
          () =>
            PopupboxManager.open({ content: <ErrorPopup errors={[err]} /> }),
          500
        );
        throw err;
      });
    }

    return result;
  } catch (err) {
    alert(err);
    throw err;
  }
};

const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancer(
    applyMiddleware(crashReporter, routerMiddleware(history), thunkMiddleware)
  )
);

ReactDOM.render(
  <App history={history} store={store} />,
  document.getElementById('root')
);
