import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledTh = styled.th`
  padding: 0 5px;
  height: 50px;
  text-align: left;
`;

function TableHead({ children }) {
  return (
    <StyledTh>
      <div>
        <span>{children}</span>
      </div>
    </StyledTh>
  );
}

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableHead;
