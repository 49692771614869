import React from 'react';
import { Box, Flex } from '@rebass/grid';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import _ from 'lodash';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';

import connect from 'react-redux/es/connect/connect';
import LinkButton from '../../common/LinkButton';
import IgnoreLinesConfiguration from './IgnoreLinesConfiguration';
import SeparatorSelector from './SeparatorSelector';
import NumberInput from '../../common/NumberInput';
import FieldsConfiguration from './FieldsConfiguration';
import { uploadFile } from '../../../reducers/newConciliationReducer';

import {
  fetchProfile,
  profileAddScanning,
  profileChangeRule,
  profileDeleteScanning,
  profileSave,
  profileToggleRule,
  profileAddIgnoredLine,
  profileChangeField,
  profileDeleteIgnoredLine,
  profileUpdate,
  profileToggleField,
  profileValidate,
  profileChangeInformative,
  profileChangeIgnoreLine,
} from '../../../reducers/profilesReducer';
import { fetchStructureMetadata } from '../../../reducers/fileStructureReducer';

import {
  mapCommonSeparatorsForSelect,
  mapComparationTypesForSelect,
  mapDataTypesForSelect,
  mapDateFormatsForSelect,
  mapIncreasecardFieldsForSelect,
  mapIncreasecardProvidersForSelect,
} from './profileHelpers';
import MatchRules from './MatchRules';
import { LockOutline } from 'styled-icons/material';
import Button from '../../common/Button';
import FullPageSpinner from '../../common/FullPageSpinner';
import ConciliationTypeSelector from '../../common/ConciliationTypeSelector';
import { withNamespaces } from 'react-i18next';
import Spinner from '../../common/Spinner';
import { translateError } from '../../../errors';

const Page = styled.div`
  .link-back {
    padding: 0;
  }
  .profile-name {
    label {
      display: block;
      color: #43425d;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
    }
    input[type='text'] {
      border: 1px solid #243568;
      border-radius: 3px;
      height: 33px;
      width: 260px;
      font-family: Open Sans, sans-serif;
      color: #243568;
      padding-left: 8px;
    }
  }
  .tabs {
    width: 100%;
    .tab-list {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        color: #243568;
        font-size: 15px;
        font-weight: 600;
        padding: 13px 28px;
        border-bottom: 2px solid #ededed;
        cursor: pointer;
      }
      .react-tabs__tab--selected {
        border-left: 2px solid #ededed;
        border-right: 2px solid #ededed;
        border-top: 3px solid #00dd00;
        border-bottom: none;
      }
    }
    .tab-list-bottom-border {
      border-bottom: 2px solid #ededed;
    }
  }
  .settings-locked {
    color: #223368;
    background-color: white;
    display: inline-block;
    padding: 10px 13px;
    margin: 24px 0;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

    svg {
      height: 21px;
      margin-right: 8px;
    }
    span {
      font-size: 14px;
    }
  }
  h2 > svg {
    height: 21px;
    color: #ededed;
  }
  .save-changes {
    padding: 8px 20px;
  }
  .verify-message-container {
    svg {
      color: #7f848f;
      width: 15px;
      height: 15px;
      margin-left: 9px;
    }
    span {
      color: #7f848f;
      font-size: 12px;
      margin-left: 9px;
    }
  }
  .upload-file-button {
    height: 100%;
    font-size: 15px;
  }
`;

class EditProfile extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchProfile(this.props.profileId));
    dispatch(fetchStructureMetadata());
  }
  onSave() {
    this.props.onSave().then(() => this.props.history.push('/'));
  }
  onDrop(event) {
    const { dispatch } = this.props;
    const file = event[0];
    dispatch(uploadFile(file)).then(() => {
      this.props.onValidate();
    });
  }
  isValid() {
    return (
      this.props.profile.name &&
      !(this.props.validating || this.props.validationErrors.code)
    );
  }
  render() {
    const {
      commonSeparators,
      comparationTypes,
      credentials,
      dataTypes,
      dateFormats,
      fetching,
      history,
      increaseCardFields,
      increaseCardProviders,
      onAddIgnoredLine,
      onAddScanning,
      onChange,
      onDeleteIgnoredLine,
      onDeleteScanning,
      onDeprecationDaysChange,
      onIgnoreLineChange,
      onInformativeChange,
      onLineNumberChange,
      onNameChange,
      onRuleChange,
      onSeparatorChange,
      onStableChange,
      onToggleRule,
      onToggleVal,
      profile,
      s3Key,
      t,
      validating,
      validationErrors,
    } = this.props;
    if (fetching) {
      return <FullPageSpinner />;
    }
    return (
      <Page className="page">
        <Flex my={15}>
          <Box>
            <LinkButton className="link-back" onClick={history.goBack}>
              Volver
            </LinkButton>
          </Box>
        </Flex>
        <Flex>
          <Box className="profile-name">
            <Flex>
              <Box>
                <label>Nombre del Perfil</label>
                <input
                  disabled={!credentials.masqueradeToken}
                  onChange={(event) => onNameChange(event)}
                  type="text"
                  value={profile && profile.name}
                />
              </Box>
              <Box mt={20}>
                <ConciliationTypeSelector
                  readOnly
                  value={profile.reconciliationType}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex mt={45}>
          <Tabs className="tabs">
            <Flex alignItems="flex-end">
              <Box>
                <TabList className="tab-list">
                  <Tab>Reglas de match</Tab>
                  <Tab>Configuración de CSV</Tab>
                </TabList>
              </Box>
              <Box className="tab-list-bottom-border" flex="1" />
            </Flex>
            <TabPanel>
              <MatchRules
                comparationTypes={comparationTypes}
                disabled={!credentials.masqueradeToken}
                fields={profile ? profile.fields : []}
                increasecardFields={increaseCardFields}
                onAddScanning={onAddScanning}
                onDeleteScanning={onDeleteScanning}
                onRuleChange={onRuleChange}
                onToggleRule={onToggleRule}
                value={profile ? profile.scannings : []}
              />
            </TabPanel>
            <TabPanel>
              <div className="settings-locked">
                <LockOutline />
                <span>
                  Las configuraciones originales no pueden modificarse
                </span>
              </div>
              <Flex>
                <Box>
                  <h2>
                    1. Estructura de archivo CSV <LockOutline />
                  </h2>
                </Box>
              </Flex>
              <Flex alignItems="center">
                <Box>
                  <span className="structure-label">Separador de columna:</span>
                </Box>
                <Box>
                  <SeparatorSelector
                    commonSeparators={commonSeparators}
                    disabled={profile && profile.originalStable}
                    onChange={(event) => onSeparatorChange(event)}
                    selected={profile && profile.delimiter}
                  />
                </Box>
                <Box ml="17px" mr="9px">
                  <span className="structure-label">
                    Empezar desde la fila:
                  </span>
                </Box>
                <Box>
                  <NumberInput
                    disabled={profile && profile.originalStable}
                    min={0}
                    onChange={(event) => onLineNumberChange(event)}
                    value={profile && profile.headersCount}
                  />
                </Box>
              </Flex>
              {credentials.masqueradeToken && (
                <Flex alignItems="center" mt={40}>
                  <Box ml="17px" mr="9px">
                    <span className="structure-label">Días de validez:</span>
                  </Box>
                  <Box>
                    <NumberInput
                      min={0}
                      onChange={(event) => onDeprecationDaysChange(event)}
                      value={profile && profile.deprecationDays}
                    />
                  </Box>
                  <Box ml="17px" mr="9px">
                    <span className="structure-label">Texto a remover:</span>
                  </Box>
                  <Box>
                    <input
                      disabled={profile && !profile.stable}
                      type="text"
                      value={profile && profile.stringToRemove}
                    />
                  </Box>
                  <Box>
                    <input
                      checked={profile && profile.stable}
                      disabled={profile.originalStable}
                      onChange={(event) => onStableChange(event.target.checked)}
                      type="checkbox"
                    />
                    Estable
                  </Box>
                </Flex>
              )}
              {credentials.masqueradeToken && (
                <Flex alignItems="center" mt={40}>
                  <Box ml="17px" mr="9px">
                    <span className="structure-label">Líneas a ignorar:</span>
                  </Box>
                  <IgnoreLinesConfiguration
                    onAddIgnoredLine={onAddIgnoredLine}
                    onChange={onIgnoreLineChange}
                    onDeleteIgnoredLine={onDeleteIgnoredLine}
                    value={profile.ignoreLines}
                  />
                </Flex>
              )}
              <Flex>
                <Box>
                  <h2>
                    2. Configuración de campos <LockOutline />
                  </h2>
                </Box>
              </Flex>
              <Flex>
                <FieldsConfiguration
                  dataTypes={dataTypes}
                  dateFormats={dateFormats}
                  fieldNames={increaseCardFields}
                  increasecardProviders={increaseCardProviders}
                  informativeFields={profile ? profile.informativeFields : []}
                  locked={profile && profile.originalStable}
                  onChange={onChange}
                  onInformativeChange={onInformativeChange}
                  onToggleVal={onToggleVal}
                  value={profile ? profile.fields : []}
                />
              </Flex>
            </TabPanel>
          </Tabs>
        </Flex>
        {credentials.masqueradeToken && (
          <Flex mb={30} mt={50}>
            {!this.isValid() && (
              <Button
                className="force-save-changes"
                onClick={(event) => this.onSave(event)}
              >
                {t('force_save_changes')}
              </Button>
            )}
            {this.isValid() && (
              <Button
                className="save-changes"
                onClick={(event) => this.onSave(event)}
              >
                {t('save_changes')}
              </Button>
            )}
            {validating && (
              <Box className="verify-message-container">
                <Spinner />
                <span>{t('verifying_file_structure')}</span>
              </Box>
            )}
            {validationErrors && validationErrors.code && (
              <Box className="verify-message-container">
                <span>
                  {t('error_code')}: {validationErrors.code}
                </span>
                <span>{translateError(validationErrors)}</span>
                {validationErrors.message && (
                  <span>{validationErrors.message}</span>
                )}
              </Box>
            )}
            {credentials.masqueradeToken && !profile.originalStable && !s3Key && (
              <Dropzone
                disableClick={false}
                multiple={false}
                onDrop={this.onDrop.bind(this)}
                style={{}}
              >
                <LinkButton className="upload-file-button">
                  Validar Configuración
                </LinkButton>
              </Dropzone>
            )}
          </Flex>
        )}
      </Page>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onNameChange: (event) =>
    dispatch(profileUpdate('name', event.currentTarget.value)),
  onAddScanning: () => dispatch(profileAddScanning()),
  onDeleteScanning: (idx) => dispatch(profileDeleteScanning(idx)),
  onToggleRule: (scanning, rule) => dispatch(profileToggleRule(scanning, rule)),
  onRuleChange: (scanning, rule, changes) =>
    dispatch(profileChangeRule(scanning, rule, changes)),
  onSeparatorChange: (separator) =>
    dispatch(profileUpdate('delimiter', separator)),
  onDeprecationDaysChange: (deprecationDays) =>
    dispatch(profileUpdate('deprecationDays', deprecationDays)),
  onStableChange: (stable) => dispatch(profileUpdate('stable', stable)),
  onLineNumberChange: (headersCount) =>
    dispatch(profileUpdate('headersCount', headersCount)),
  onAddIgnoredLine: () => dispatch(profileAddIgnoredLine()),
  onStringToRemoveChange: (event) =>
    dispatch(profileUpdate('stringToRemove', event.currentTarget.value)),
  onIgnoreLineChange: (line, changes) =>
    dispatch(profileChangeIgnoreLine(line, changes)),
  onDeleteIgnoredLine: (idx) => dispatch(profileDeleteIgnoredLine(idx)),
  onToggleVal: (val) => dispatch(profileToggleField(val)),
  onInformativeChange: (fieldName, event) =>
    dispatch(profileChangeInformative(fieldName, event.target.checked)),
  onChange: (field, value) => dispatch(profileChangeField(field, value)),
  onSave: () => dispatch(profileSave()),
  onValidate: () => dispatch(profileValidate(null)),
});

function mapStateToProps(fullState) {
  const pathname = _.get(fullState, 'router.location.pathname', null);
  const profileId = pathname.replace('/edit_profile/', '');

  const fileStructure = fullState.fileStructure;
  const profiles = fullState.profiles;
  const { s3Key } = fullState.newConciliation;

  return {
    profileId,
    s3Key,
    commonSeparators: mapCommonSeparatorsForSelect(
      fileStructure.commonSeparators
    ),
    dataTypes: mapDataTypesForSelect(fileStructure.dataTypes),
    increaseCardFields: mapIncreasecardFieldsForSelect(
      profiles.profile
        ? fileStructure.increasecardFields[profiles.profile.reconciliationType]
        : []
    ),
    dateFormats: mapDateFormatsForSelect(fileStructure.dateFormats),
    comparationTypes: mapComparationTypesForSelect(
      fileStructure.comparationTypes
    ),
    increaseCardProviders: mapIncreasecardProvidersForSelect(
      fileStructure.increasecardProviders
    ),

    profile: profiles.profile,
    fetching: profiles.fetching || fileStructure.fetching,
    credentials: fullState.auth.credentials,
    validationErrors: profiles.validationErrors,
    validating: profiles.validating,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(EditProfile));
