import React from 'react';
import styled from 'styled-components';
import { Close } from 'styled-icons/material';
import Card from './Card';
import LinkButton from './LinkButton';
import { PopupboxManager } from 'react-popupbox';
import { withNamespaces } from 'react-i18next';

const StyledCard = styled(Card)`
  width: 527px;
  height: 268px;
`;

const CloseButton = styled(LinkButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  & > svg {
    width: 16px;
    height: 16px;
    color: black;
  }
`;

function ErrorPopup(props) {
  const { t } = props;

  return (
    <StyledCard>
      <CloseButton onClick={PopupboxManager.close}>
        <Close />
      </CloseButton>
      <div>
        <h1>{t('there_was_an_error')}</h1>
        <p>
          {t('try_again_later')}{' '}
          <a href="mailto:soporte@increasecard.com">soporte@increasecard.com</a>
        </p>
        <p>{t('sorry_for_the_incovenience')}</p>
      </div>
    </StyledCard>
  );
}

ErrorPopup.propTypes = {};

export default withNamespaces()(ErrorPopup);
