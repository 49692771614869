import React from 'react';
import styled from 'styled-components';
import Select from '../../common/Select';
import { Box, Flex } from '@rebass/grid';
import PropTypes from 'prop-types';
import LinkButton from '../../common/LinkButton';
import Toggle from '../../common/Toggle';
import { PopupboxManager } from 'react-popupbox';
import AdvancedCardConfigurationPopup from './AdvancedCardConfigurationPopup';
import NumberInput from '../../common/NumberInput';
import classnames from 'classnames';
import { LockOutline } from 'styled-icons/material';

const RegTypeSelect = styled(Select)`
  width: 197px;
`;

const DateFormatSelect = styled(Select)`
  width: 164px;
  margin-left: 6px;
`;

const MoneySeparatorSelect = styled(Select)`
  width: 103px;
  margin-left: 6px;
`;

const InformativeOrderLabel = styled.label`
  color: black;
  padding: 0 10px;
`;

const HorizontalSeparator = styled.div`
  background-color: #ededed;
  width: 1px;
  height: 35px;
  margin: 0 14px;
`;

const MONEY_SEPARATORS = [
  { name: 'Ninguno', value: '' },
  { name: 'Coma (,)', value: ',' },
  { name: 'Punto (.)', value: '.' },
];

class ConfigurationRow extends React.Component {
  openAdvancedConfiguration() {
    const { value, locked, increasecardProviders } = this.props;
    PopupboxManager.open({
      content: (
        <AdvancedCardConfigurationPopup
          increaseCardProviders={increasecardProviders}
          key={value.location}
          onSave={(value) => this.onKeyChange('comparators', value)}
          onlyRead={locked}
          value={value.comparators}
        />
      ),
      config: {
        fadeOut: true,
        fadeOutSpeed: 100,
        fadeIn: true,
        fadeInSpeed: 100,
      },
    });
  }

  onKeyChange(key, value) {
    this.props.onChange({ [key]: value });
  }

  render() {
    let regTypeDetail = <Box className="reg-type-detail" />;
    const {
      locked,
      value,
      dateFormats,
      informativeFields,
      onToggle,
      field,
      onInformativeChange,
      dataTypes,
    } = this.props;
    const disabled = !value;

    this.informativeOrder = informativeFields.indexOf(this.props.field.value);
    this.informative = this.informativeOrder !== -1;

    if (value) {
      switch (value.dataType) {
        case 'date':
          regTypeDetail = (
            <Box className="reg-type-detail">
              <Flex alignItems="center">
                <Box>
                  <HorizontalSeparator />
                </Box>
                <Box>
                  <span>Formato</span>
                </Box>
                <Box>
                  <DateFormatSelect
                    disabled={locked}
                    onSelect={this.onKeyChange.bind(this, 'dateFormat')}
                    options={dateFormats}
                    selected={value.dateFormat}
                  />
                </Box>
              </Flex>
            </Box>
          );
          break;
        case 'money':
          regTypeDetail = (
            <Box className="reg-type-detail">
              <Flex alignItems="center">
                <Box>
                  <HorizontalSeparator />
                </Box>
                <Box>
                  <span>Separador de centavos</span>
                </Box>
                <Box>
                  <MoneySeparatorSelect
                    disabled={locked}
                    onSelect={this.onKeyChange.bind(this, 'centsSeparator')}
                    options={MONEY_SEPARATORS}
                    selected={value.centsSeparator}
                  />
                </Box>
              </Flex>
            </Box>
          );
          break;
        case 'string':
          regTypeDetail = (
            <Box className="reg-type-detail">
              <Flex alignItems="center">
                <Box>
                  <HorizontalSeparator />
                </Box>
                <LinkButton onClick={this.openAdvancedConfiguration.bind(this)}>
                  Configuración avanzada
                </LinkButton>
              </Flex>
            </Box>
          );
          break;
        default:
          break;
      }
    }
    return (
      <tr className={classnames({ disabled })}>
        <td>
          <Flex>
            <Box flex="1">{field.name}</Box>
            {locked && (
              <Box className="lock-container">
                <LockOutline />
              </Box>
            )}
          </Flex>
        </td>
        <td>
          <NumberInput
            className="col-number"
            disabled={disabled || locked}
            min={0}
            onChange={this.onKeyChange.bind(this, 'fieldALocation')}
            value={value ? value.fieldALocation : 0}
          />
        </td>
        <td>
          <Flex alignItems="center">
            <Box>
              <RegTypeSelect
                className="reg-type"
                disabled={disabled || locked}
                onSelect={this.onKeyChange.bind(this, 'dataType')}
                options={dataTypes}
                selected={value ? value.dataType : null}
              />
            </Box>
            {regTypeDetail}
          </Flex>
        </td>
        <td>
          <Flex alignItems="center">
            <input
              checked={this.informative}
              onChange={onInformativeChange}
              type="checkbox"
            />
            {this.informative && (
              <InformativeOrderLabel>
                {this.informativeOrder}
              </InformativeOrderLabel>
            )}
          </Flex>
        </td>
        <td>
          <Box width={32}>
            <Toggle checked={!disabled} onChange={onToggle} />
          </Box>
        </td>
      </tr>
    );
  }
}

ConfigurationRow.propTypes = {
  dataTypes: PropTypes.array,
  dateFormats: PropTypes.array,
  increasecardProviders: PropTypes.array,
  informativeFields: PropTypes.array,
  locked: PropTypes.bool,
  onChange: PropTypes.func,
  onInformativeChange: PropTypes.func,
  onToggle: PropTypes.func,
  value: PropTypes.object,
};

export default ConfigurationRow;
