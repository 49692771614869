import { mapArrayForSelect } from '../../../helpers';
import _ from 'lodash';

const COMMON_SEPARATORS = {
  ',': 'Coma (,)',
  ';': 'Punto y Coma (;)',
  '\t': 'Tabulación',
};

const INCREASECARD_FIELDS = {
  provider: 'Tarjeta',
  amount_cents: 'Monto de venta',
  origin_date: 'Fecha de venta',
  pay_date: 'Fecha de cobro',
  payment_date: 'Fecha de cobro',
  total_earn_cents: 'Total acreditado',
  store_number: 'Número de establecimiento',
  batch: 'Lote',
  authorization_number: 'Número de Autorización',
  presentation_date: 'Fecha de Presentación',
  description: 'Descripción',
  voucher: 'Cupón',
  card: 'Número de tarjeta (últ 4)',
  installments: 'Cuotas',
  clearing_number: 'Número de Liquidación',
  arancel: 'Arancel',
  bin: 'BIN',
};

const INCREASECARD_PROVIDERS = {
  amex: 'American Express',
  visa: 'Visa',
  mastercard: 'Mastercard',
  cabal: 'Cabal',
  mercadopago: 'Mercado Pago',
  naranja: 'Naranja',
};

const DATA_TYPES = {
  string: 'Texto',
  date: 'Fecha',
  long: 'Número Largo',
  bin: 'BIN',
  money: 'Dinero',
  card: 'Tarjeta Débito/Crédito',
  integer: 'Numérico',
};

const COMPARATION_TYPES = {
  '=': 'Identicos entre si',
  range: 'Mayor / Menor a',
  '!=': 'Distintos',
};

export const mapCommonSeparatorsForSelect = _.partial(
  mapArrayForSelect,
  COMMON_SEPARATORS
);
export const mapIncreasecardFieldsForSelect = (val) => {
  return val.map((v) => {
    return {
      value: v.location,
      name: _.get(INCREASECARD_FIELDS, v.location, v.location),
    };
  });
};

export const mapIncreasecardProvidersForSelect = _.partial(
  mapArrayForSelect,
  INCREASECARD_PROVIDERS
);
export const mapDataTypesForSelect = _.partial(mapArrayForSelect, DATA_TYPES);

export const mapComparationTypesForSelect = _.partial(
  mapArrayForSelect,
  COMPARATION_TYPES
);
export const mapDateFormatsForSelect = _.partial(mapArrayForSelect, {});
