import client from './apiClient';
import _ from 'lodash';

function mapServerProfileToLocal(profile) {
  const fileStructure = profile.structure_a;
  const increaseStructure = profile.structure_b;
  return {
    id: profile.id,
    name: profile.name,
    reconciliationType: profile.reconciliation_type,
    headersCount: fileStructure.headers_count,
    stringToRemove: fileStructure.string_to_remove,
    deprecationDays: profile.deprecation_days,
    stable: profile.stable,
    originalStable: profile.stable,
    delimiter: fileStructure.delimiter,
    informativeFields: profile.informative_fields.map(
      (informativeField) => informativeField.field
    ),
    ignoreLines: (fileStructure.ignore_lines || []).map((c) => {
      return { position: c.position, value: c.value };
    }),
    fields: fileStructure.fields.map((f) => {
      return {
        id: f.id,
        name: f.name,
        fieldALocation: f.location,
        fieldBLocation: _.find(increaseStructure.fields, { name: f.name })
          .location,
        amountCents: f.amount_cents,
        centsSeparator: f.cents_separator,
        dateFormat: f.date_format,
        dataType: f.data_type,
        position: f.position,
        comparators: (f.comparators || []).map((c) => {
          return {
            originalValue: c.original_value,
            possibleValues: c.possible_values.join(', '),
          };
        }),
      };
    }),
    scannings: profile.scannings.map((s) => {
      return {
        number: s.number,
        scanningFields: s.scanning_fields.map((sf) => {
          return {
            fieldALocation: sf.field_a_location,
            fieldBLocation: sf.field_b_location,
            comparationType: sf.comparation_type,
            threshold: sf.threshold,
          };
        }),
      };
    }),
    conciliationsCount: profile.conciliations_count,
    lastConciliationDate: profile.last_conciliation_date,
  };
}

function mapLocalProfileToValidate(profile) {
  return {
    headers_count: profile.headersCount,
    string_to_remove: profile.stringToRemove,
    delimiter: profile.delimiter,
    fields: profile.fields.map((f) => {
      return {
        name: f.fieldBLocation,
        location: f.fieldALocation,
        data_type: f.dataType,
        cents_separator: f.centsSeparator,
        date_format: f.dateFormat,
      };
    }),
  };
}

function mapLocalProfileToSave(profile) {
  return {
    name: profile.name,
    reconciliation_type: profile.reconciliationType,
    stable: profile.stable,
    deprecation_days: profile.deprecationDays,
    informative_fields: profile.informativeFields.map((fieldName, order) => {
      return { field: fieldName, order: order };
    }),
    structure: {
      delimiter: profile.delimiter,
      headers_count: profile.headersCount,
      string_to_remove: profile.stringToRemove,
      ignore_lines: profile.ignoreLines.map((lineToIgnore) => {
        return {
          position: lineToIgnore.position,
          value: lineToIgnore.value,
        };
      }),
      fields: profile.fields.map((f) => {
        return {
          name: f.fieldBLocation,
          increase_equivalent: f.fieldBLocation,
          location: f.fieldALocation,
          data_type: f.dataType,
          cents_separator: f.centsSeparator,
          date_format: f.dateFormat,
          comparators: f.comparators.map((comparator) => {
            return {
              original_value: comparator.originalValue,
              possible_values: comparator.possibleValues
                .split(',')
                .map((s) => s.trim()),
            };
          }),
        };
      }),
    },
    scannings: profile.scannings.map((s, i) => {
      return {
        number: i + 1,
        scanning_fields: s.scanningFields.map((sf) => {
          const fieldALocation = _.find(profile.fields, {
            fieldBLocation: sf.fieldBLocation,
          }).fieldALocation;
          return {
            field_a_location: fieldALocation,
            field_b_location: sf.fieldBLocation,
            comparation_type: sf.comparationType,
            threshold: sf.threshold,
          };
        }),
      };
    }),
  };
}

function mapLocalProfileToUpdate(profile) {
  return {
    name: profile.name,
    deprecation_days: profile.deprecationDays,
    informative_fields: profile.informativeFields.map((fieldName, order) => {
      return { field: fieldName, order: order };
    }),
    structure: {
      ignore_lines: profile.ignoreLines.map((lineToIgnore) => {
        return {
          position: lineToIgnore.position,
          value: lineToIgnore.value,
        };
      }),
    },
    scannings: profile.scannings.map((s, i) => {
      return {
        number: i + 1,
        scanning_fields: s.scanningFields.map((sf) => {
          const field = _.find(profile.fields, {
            fieldBLocation: sf.fieldBLocation,
          });
          return {
            field_a_location: field.fieldALocation,
            field_b_location: sf.fieldBLocation,
            comparation_type: sf.comparationType,
            threshold: sf.threshold,
          };
        }),
      };
    }),
  };
}

function extractData(res) {
  return res.data;
}

export function getProfiles({ type }) {
  const params = { data_type: type || undefined };
  return client
    .get('/reconciliation_profiles', { params })
    .then(extractData)
    .then((profiles) => profiles.map(mapServerProfileToLocal));
}

export function getProfile(profileId) {
  return client
    .get(`/reconciliation_profiles/${profileId}`)
    .then(extractData)
    .then(mapServerProfileToLocal);
}

export function deleteProfile(profileId) {
  return client.delete(`/reconciliation_profiles/${profileId}`);
}

export function postProfile(profile) {
  return client.post(`/reconciliation_profiles`, {
    reconciliation_profile: mapLocalProfileToSave(profile),
  });
}

export function saveProfile(profile) {
  if (profile.id) {
    if (profile.originalStable) {
      return client.patch(`/reconciliation_profiles/${profile.id}`, {
        id: profile.id,
        reconciliation_profile: mapLocalProfileToUpdate(profile),
      });
    }
    return deleteProfile(profile.id).then(() => {
      return createProfile(profile);
    });
  }
  return createProfile(profile);
}

export function createProfile(profile) {
  return client.post(`/reconciliation_profiles`, {
    reconciliation_profile: mapLocalProfileToSave(profile),
  });
}

export function validateProfile(fileKey, profile) {
  return client
    .post('structures/validate', {
      file_key: fileKey,
      structure: mapLocalProfileToValidate(profile),
    })
    .then(extractData);
}
