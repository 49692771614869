import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '../../common/Table';
import { FileDownload } from 'styled-icons/material';
import { Box, Flex } from '@rebass/grid';
import LinkButton from '../../common/LinkButton';
import Spinner from '../../common/Spinner';
import classnames from 'classnames';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

const DownloadIcon = styled(FileDownload)`
  width: 22px;
  height: 22px;
  color: #00dd00;
`;

const LeftTH = styled.th`
  text-align: left;
`;

const CenterTD = styled.td`
  text-align: center;
`;

const CsvDownload = styled(LinkButton)`
  display: block;
  width: 70px;
  text-align: center;
  margin: 0 auto;
`;
const LastDownload = styled.span`
  display: block;
  text-align: center;
  font-size: 10px;
  color: #a3a3a3;
  font-weight: 600;
`;

const TDWithDisable = styled.td`
  &.disabled > * {
    opacity: 0.5;
  }
`;

function ReportsTableRow({ report, onDetailClicked, onDownloadClicked, t }) {
  return (
    <tr>
      <CenterTD width="150px">{report.creationDate}</CenterTD>
      <td style={{ padding: 0 }}>
        <Flex alignItems="center">
          <Box flex="10 1 auto" mx={15}>
            <p>{report.name}</p>
          </Box>
          {report.status === 0 && (
            <Box flex="1 10">
              <Spinner />
            </Box>
          )}
          {report.status !== 0 && report.conciliationResults && (
            <Box flex="0 0 100px" mx={15} style={{ textAlign: 'right' }}>
              <LinkButton
                onClick={onDetailClicked.bind(null, report)}
                type="button"
              >
                {t('see_details')}
              </LinkButton>
            </Box>
          )}
        </Flex>
      </td>
      <TDWithDisable
        className={classnames({ disabled: report.status === 0 })}
        width="250px"
      >
        <CsvDownload
          disabled={report.status === 0}
          onClick={onDownloadClicked.bind(null, report.id)}
          type="button"
        >
          CSV
        </CsvDownload>
        <LastDownload>
          {t('last_download')}{' '}
          {report.lastDownloadAt
            ? moment(report.lastDownloadAt).format('DD/MM/YYYY')
            : t('never')}
        </LastDownload>
      </TDWithDisable>
    </tr>
  );
}

ReportsTableRow.propTypes = {
  onDetailClicked: PropTypes.func,
  onDownloadClicked: PropTypes.func,
  report: PropTypes.object,
};

function ReportsTable({
  loading,
  data,
  onDownloadClicked,
  onDetailClicked,
  t,
}) {
  return (
    <Table loading={loading}>
      <thead>
        <tr>
          <th>{t('report_date')}</th>
          <LeftTH>{t('conciliation_type')}</LeftTH>
          <th>
            <DownloadIcon />
            {t('download')}
          </th>
        </tr>
      </thead>

      <tbody>
        {data.map((val, i) => {
          return (
            <ReportsTableRow
              key={i}
              onDetailClicked={onDetailClicked}
              onDownloadClicked={onDownloadClicked}
              report={val}
              t={t}
            />
          );
        })}
      </tbody>
    </Table>
  );
}

ReportsTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onDetailClicked: PropTypes.func,
  onDownloadClicked: PropTypes.func,
};

export default withNamespaces()(ReportsTable);
