import React from 'react';
import { Box, Flex } from '@rebass/grid';

import connect from 'react-redux/es/connect/connect';
import LinkButton from '../../common/LinkButton';
import styled from 'styled-components';
import SeparatorSelector from './SeparatorSelector';
import FieldsConfiguration from './FieldsConfiguration';
import IgnoreLinesConfiguration from './IgnoreLinesConfiguration';
import { fetchStructureMetadata } from '../../../reducers/fileStructureReducer';
import {
  mapCommonSeparatorsForSelect,
  mapComparationTypesForSelect,
  mapDataTypesForSelect,
  mapDateFormatsForSelect,
  mapIncreasecardFieldsForSelect,
  mapIncreasecardProvidersForSelect,
} from './profileHelpers';
import MatchRules from './MatchRules';
import {
  profileAddIgnoredLine,
  profileAddScanning,
  profileChangeField,
  profileChangeIgnoreLine,
  profileChangeRule,
  profileDeleteIgnoredLine,
  profileDeleteScanning,
  profileSave,
  profileToggleField,
  profileToggleRule,
  profileChangeInformative,
  profileUpdate,
  profileUpdateReconciliationType,
} from '../../../reducers/profilesReducer';
import NumberInput from '../../common/NumberInput';
import Button from '../../common/Button';
import Spinner from '../../common/Spinner';
import FullPageSpinner from '../../common/FullPageSpinner';
import { translateError } from '../../../errors';
import { withNamespaces } from 'react-i18next';
import ConciliationTypeSelector from '../../common/ConciliationTypeSelector';

const Page = styled.div`
  .link-back {
    padding: 0;
  }
  .profile-name {
    label {
      display: block;
      color: #43425d;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
    }
    input {
      border: 1px solid #243568;
      border-radius: 3px;
      height: 33px;
      width: 260px;
      font-family: Open Sans, sans-serif;
      color: #243568;
      padding-left: 8px;
    }
  }
  .structure-label {
    color: #243568;
    font-size: 13px;
    font-family: Open Sans, sans-serif;
  }
  .save-changes {
    padding: 8px 20px;
  }
  .force-save-changes {
    padding: 8px 20px;
    background-color: #ff3b3b;
  }
  .verify-message-container {
    svg {
      color: #7f848f;
      width: 15px;
      height: 15px;
      margin-left: 9px;
    }
    span {
      color: #7f848f;
      font-size: 12px;
      margin-left: 9px;
    }
  }
`;

class NewProfile extends React.Component {
  componentDidMount() {
    if (!this.props.s3Key) {
      this.props.history.push('/');
      return;
    }
    const { dispatch } = this.props;
    dispatch(fetchStructureMetadata());
  }

  onSave() {
    this.props.onSave().then(() => this.props.history.push('/'));
  }
  isValid() {
    return (
      this.props.profile.name &&
      !(this.props.validating || this.props.validationErrors.code)
    );
  }
  render() {
    const {
      commonSeparators,
      comparationTypes,
      dataTypes,
      dateFormats,
      fetching,
      history,
      increaseCardFields,
      increaseCardProviders,
      onAddIgnoredLine,
      onAddScanning,
      onChange,
      onDeleteIgnoredLine,
      onDeleteScanning,
      onDeprecationDaysChange,
      onIgnoreLineChange,
      onInformativeChange,
      onLineNumberChange,
      onNameChange,
      onRuleChange,
      onSeparatorChange,
      onStringToRemoveChange,
      onToggleRule,
      onToggleVal,
      onTypeChange,
      profile,
      t,
      validating,
      validationErrors,
    } = this.props;

    if (fetching) {
      return <FullPageSpinner />;
    }
    return (
      <Page>
        <Flex my={15}>
          <Box>
            <LinkButton className="link-back" onClick={history.goBack}>
              {t('back')}
            </LinkButton>
          </Box>
        </Flex>
        <Flex>
          <Box className="profile-name">
            <label>{t('profile_name')}</label>
            <input
              onChange={(event) => onNameChange(event)}
              type="text"
              value={profile && profile.name}
            />
          </Box>
          <Box mt={20}>
            <ConciliationTypeSelector
              allowEmpty={false}
              onChange={onTypeChange}
              value={profile.reconciliationType}
            />
          </Box>
        </Flex>
        <Flex mt={40}>
          <Box>
            <h2>1. {t('file_structure')}</h2>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <Box>
            <span className="structure-label">{t('column_separator')}:</span>
          </Box>
          <Box>
            <SeparatorSelector
              commonSeparators={commonSeparators}
              onChange={(event) => onSeparatorChange(event)}
              selected={profile && profile.delimiter}
            />
          </Box>
          <Box ml="17px" mr="9px">
            <span className="structure-label">{t('start_from_row')}:</span>
          </Box>
          <Box>
            <NumberInput
              min={0}
              onChange={(event) => onLineNumberChange(event)}
              value={profile && profile.headersCount}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" mt={40}>
          <Box ml="17px" mr="9px">
            <span className="structure-label">{t('days_of_validity')}:</span>
          </Box>
          <Box>
            <NumberInput
              min={0}
              onChange={(event) => onDeprecationDaysChange(event)}
              value={profile && profile.deprecationDays}
            />
          </Box>
          <Box ml="17px" mr="9px">
            <span className="structure-label">{t('text_to_remove')}:</span>
          </Box>
          <Box>
            <input
              min={0}
              onChange={(event) => onStringToRemoveChange(event)}
              type="text"
              value={profile && profile.stringToRemove}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" mt={40}>
          <Box ml="17px" mr="9px">
            <span className="structure-label">{t('lines_to_ignore')}:</span>
          </Box>
          <IgnoreLinesConfiguration
            onAddIgnoredLine={onAddIgnoredLine}
            onChange={onIgnoreLineChange}
            onDeleteIgnoredLine={onDeleteIgnoredLine}
            value={profile.ignoreLines}
          />
        </Flex>
        <Flex mt={40}>
          <Box>
            <h2>2. {t('fields_configuration')}</h2>
          </Box>
        </Flex>
        <Flex>
          <FieldsConfiguration
            dataTypes={dataTypes}
            dateFormats={dateFormats}
            fieldNames={increaseCardFields}
            increasecardProviders={increaseCardProviders}
            informativeFields={profile.informativeFields}
            onChange={onChange}
            onInformativeChange={onInformativeChange}
            onToggleVal={onToggleVal}
            value={profile.fields}
          />
        </Flex>
        <Flex mt={60}>
          <Box>
            <h2>3. {t('match_rules')}</h2>
          </Box>
        </Flex>
        <Flex>
          <MatchRules
            comparationTypes={comparationTypes}
            fields={profile.fields}
            increasecardFields={increaseCardFields}
            onAddScanning={onAddScanning}
            onDeleteScanning={onDeleteScanning}
            onRuleChange={onRuleChange}
            onToggleRule={onToggleRule}
            value={profile.scannings}
          />
        </Flex>
        <Flex alignItems="center" mb={35} mt={50}>
          <Box>
            {!this.isValid() && (
              <Button
                className="force-save-changes"
                onClick={(event) => this.onSave(event)}
              >
                {t('force_save_changes')}
              </Button>
            )}
            {this.isValid() && (
              <Button
                className="save-changes"
                onClick={(event) => this.onSave(event)}
              >
                {t('save_changes')}
              </Button>
            )}
          </Box>
          {validating && (
            <Box className="verify-message-container">
              <Spinner />
              <span>{t('verifying_file_structure')}</span>
            </Box>
          )}
          {validationErrors && validationErrors.code && (
            <Box className="verify-message-container">
              <span>
                {t('error_code')}: {validationErrors.code}
              </span>
              <span>{translateError(validationErrors)}</span>
              {validationErrors.message && (
                <span>{validationErrors.message}</span>
              )}
            </Box>
          )}
        </Flex>
      </Page>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onSeparatorChange: (separator) =>
    dispatch(profileUpdate('delimiter', separator)),
  onNameChange: (event) =>
    dispatch(profileUpdate('name', event.currentTarget.value)),
  onTypeChange: (value) => dispatch(profileUpdateReconciliationType(value)),
  onLineNumberChange: (headersCount) =>
    dispatch(profileUpdate('headersCount', headersCount)),
  onDeprecationDaysChange: (deprecationDays) =>
    dispatch(profileUpdate('deprecationDays', deprecationDays)),
  onStringToRemoveChange: (event) =>
    dispatch(profileUpdate('stringToRemove', event.currentTarget.value)),
  onToggleVal: (val) => dispatch(profileToggleField(val)),
  onChange: (field, value) => dispatch(profileChangeField(field, value)),
  onAddScanning: () => dispatch(profileAddScanning()),
  onDeleteScanning: (idx) => dispatch(profileDeleteScanning(idx)),
  onAddIgnoredLine: () => dispatch(profileAddIgnoredLine()),
  onDeleteIgnoredLine: (idx) => dispatch(profileDeleteIgnoredLine(idx)),
  onToggleRule: (scanning, rule) => dispatch(profileToggleRule(scanning, rule)),
  onInformativeChange: (fieldName, event) =>
    dispatch(profileChangeInformative(fieldName, event.target.checked)),
  onRuleChange: (scanning, rule, changes) =>
    dispatch(profileChangeRule(scanning, rule, changes)),
  onIgnoreLineChange: (line, changes) =>
    dispatch(profileChangeIgnoreLine(line, changes)),
  onSave: () =>
    dispatch(profileSave()).then(() => dispatch({ type: 'CLEAR_FILE_UPLOAD' })),
});

function mapStateToProps(fullState) {
  const fileStructure = fullState.fileStructure;
  const profiles = fullState.profiles;
  return {
    commonSeparators: mapCommonSeparatorsForSelect(
      fileStructure.commonSeparators
    ),
    dataTypes: mapDataTypesForSelect(fileStructure.dataTypes),
    increaseCardFields: mapIncreasecardFieldsForSelect(
      fileStructure.increasecardFields[profiles.profile.reconciliationType]
    ),
    dateFormats: mapDateFormatsForSelect(fileStructure.dateFormats),
    comparationTypes: mapComparationTypesForSelect(
      fileStructure.comparationTypes
    ),
    increaseCardProviders: mapIncreasecardProvidersForSelect(
      fileStructure.increasecardProviders
    ),
    s3Key: fullState.newConciliation.s3Key,
    validating: profiles.validating,
    validationErrors: profiles.validationErrors,
    profile: profiles.profile,
    fetching: fileStructure.fetching,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewProfile));
