import { getStructureMetadata } from '../services/fileStructureService';
import { objectKeysToCamelCase } from '../helpers';

const INITIAL_STATE = {
  commonSeparators: [],
  increasecardFields: { movements: [], payments: [] },
  increasecardProviders: [],
  dataTypes: [],
  comparationTypes: [],
  dateFormats: [],
  fetching: false,
};

export function fetchStructureMetadata() {
  return (dispatch) => {
    dispatch({ type: 'STRUCTURE_METADATA_REQUEST' });
    return getStructureMetadata().then((json) =>
      dispatch({
        type: 'STRUCTURE_METADATA_FETCHED',
        ...objectKeysToCamelCase(json.data),
      })
    );
  };
}

const increaseDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'STRUCTURE_METADATA_REQUEST':
      return {
        ...state,
        commonSeparators: [],
        increasecardFields: { movements: [], payments: [] },
        increasecardProviders: [],
        dataTypes: [],
        comparationTypes: [],
        dateFormats: [],
        fetching: true,
      };
    case 'STRUCTURE_METADATA_FETCHED':
      return {
        ...state,
        commonSeparators: action.commonSeparators,
        increasecardFields: action.increasecardFields,
        increasecardProviders: action.increasecardProviders,
        dataTypes: action.dataTypes,
        comparationTypes: action.comparationTypes,
        dateFormats: action.dateFormats,
        fetching: false,
      };
    default:
      return state;
  }
};

export default increaseDataReducer;
