import React from 'react';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Edit } from 'styled-icons/fa-solid';
import { Clone } from 'styled-icons/fa-regular';
import ProfileCardContainer from './ProfileCardContainer';
import LinkButton from './../../common/LinkButton';
import { withNamespaces } from 'react-i18next';

const Row = styled.div`
  border-bottom: 1px solid #ededed;
  text-align: left;
`;

const BasicProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  p {
    font-size: 14px;
  }
`;

const ProfileStats = styled.div``;

const TitleContent = styled.p`
  font-weight: bold;
  font-size: 15px;
  margin: 0;

  :first-letter {
    text-transform: capitalize;
  }
`;

const Stats = styled.p`
  font-size: 12px;
  color: #465253;
  text-align: left;
  margin: 0px;
`;

const ActionText = styled(LinkButton)`
  color: #3b86ff;
  font-size: 13px;
  padding-left: 3px;
`;

const EditIcon = styled(Edit)`
  color: #3b86ff;
  width: 16px;
  height: 17px;
`;

const ClonIcon = styled(Clone)`
  color: #3b86ff;
  width: 16px;
  height: 17px;
`;

const Action = styled.div`
  text-align: left;
  margin: 0px;
`;

const DeleteActionText = styled(ActionText)`
  color: #ff3b3b;
  text-decoration: underline;
`;

class ProfileCard extends React.Component {
  onDrop(event) {
    const file = event[0];
    this.props.onClone(file);
  }

  render() {
    const { t } = this.props;

    return (
      <ProfileCardContainer>
        {this.props.masquerading && (
          <div>
            <Row>
              <TitleContent>{this.props.name}</TitleContent>
            </Row>
            <Row>
              <Stats>
                {t('last_conciliation')}:{' '}
                {this.props.lastConciliationDate !== 'N/A'
                  ? moment(this.props.lastConciliationDate).format('DD/MM/YYYY')
                  : t('never')}
              </Stats>
              <Stats>
                {t('total_scannings')} {this.props.totalScannings}
              </Stats>
              <Stats>
                {t('conciliations_count')} {this.props.conciliationsCount}
              </Stats>
              <Action>
                <EditIcon />
                <ActionText onClick={this.props.onDetail}>
                  {t('see_details')}
                </ActionText>
              </Action>
              <Action>
                <ClonIcon />
                <LinkButton>
                  <Dropzone
                    disableClick={false}
                    multiple={false}
                    onDrop={this.onDrop.bind(this)}
                    style={{}}
                  >
                    {t('clone_profile')}
                  </Dropzone>
                </LinkButton>
                <ActionText onClick={this.props.onClone} />
              </Action>
            </Row>
            <div>
              <Action>
                <DeleteActionText onClick={this.props.onDestroy}>
                  {t('delete')}
                </DeleteActionText>
              </Action>
            </div>
          </div>
        )}
        {!this.props.masquerading && (
          <BasicProfileCard>
            <TitleContent>{this.props.name}</TitleContent>
            <ProfileStats>
              <Stats>
                {t('last_conciliation')}:{' '}
                {this.props.lastConciliationDate !== 'N/A'
                  ? moment(this.props.lastConciliationDate).format('DD/MM/YYYY')
                  : t('never')}
              </Stats>
              <Stats>
                {t('total_scannings')} {this.props.totalScannings}
              </Stats>
              <Stats>
                {t('conciliations_count')} {this.props.conciliationsCount}
              </Stats>
            </ProfileStats>
            <Action>
              <EditIcon />
              <ActionText onClick={this.props.onDetail}>
                {t('see_details')}
              </ActionText>
            </Action>
          </BasicProfileCard>
        )}
      </ProfileCardContainer>
    );
  }
}

ProfileCard.propTypes = {
  conciliationsCount: PropTypes.number.isRequired,
  lastConciliationDate: PropTypes.string.isRequired,
  masquerading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClone: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired,
  totalScannings: PropTypes.number.isRequired,
};

export default withNamespaces()(ProfileCard);
