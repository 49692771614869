import client from './apiClient';

function conciliate(ids) {
  return client.post('/increase_payments/conciliate', { ids: ids });
}

function getPayments(page, filters, sorting) {
  return client.get('/increase_payments', {
    params: { page, ...filters, ...sorting },
  });
}

export default {
  conciliate,
  getPayments,
};
