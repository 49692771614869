import React from 'react';

export class V2 extends React.PureComponent {
  componentDidMount() {
    const { authToken, masqueradeToken } = this.props.location.state;
    const newUrl = `${
      process.env.REACT_APP_V2_URL
    }?auth_token=${authToken}&masquerade_token=${masqueradeToken}`;
    window.location.assign(newUrl);
  }

  render() {
    return null;
  }
}

export default V2;
