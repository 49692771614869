import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../common/Table';
import styled from 'styled-components';
import { SortDown, SortUp } from 'styled-icons/fa-solid';
import classnames from 'classnames';
import { CheckSquare, Square } from 'styled-icons/fa-regular';
import { formatCurrency } from '../../../helpers';

const StyledTable = styled(Table)`
  border: 1px solid #ededed;
  border-spacing: 0;

  th {
    padding: 0 5px;
    height: 50px;
  }
  th.sortable div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    svg {
      width: 10px;
      height: 10px;
      color: #a3a3a3;
    }
    svg.sort-selected {
      color: #3b86ff;
    }
  }
  tr {
    svg {
      width: 15px;
      height: 15px;
    }
    td {
      font-weight: normal;
      background-color: white;
      border-top: 2px solid #ededed;
    }
  }
  tr.conciliated * {
    color: rgba(0, 221, 0, 0.2);
  }
  tr.deprecated * {
    color: rgba(255, 59, 59, 0.2);
  }

  & tr:nth-child(even) td {
    background-color: white;
  }

  tr.selected {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16);
    transform: scale(1.01);
    td {
      background-color: #f2f6f7;
    }
  }
`;

/* eslint-disable no-eq-null,eqeqeq */
class ConciliationsTableRow extends React.Component {
  onClick() {
    if (
      this.props.conciliation.scanningNumber == null &&
      !this.props.disabled
    ) {
      this.props.onSelectChange(!this.props.selected);
    }
  }
  render() {
    return (
      <tr
        className={classnames({
          selected: this.props.selected,
          conciliated: this.props.conciliation.scanningNumber != null,
          deprecated: this.props.conciliation.scanningNumber === 0,
        })}
        onClick={this.onClick.bind(this)}
      >
        <td>
          {!this.props.disabled &&
            (this.props.selected ||
            this.props.conciliation.scanningNumber !== null ? (
              <CheckSquare />
            ) : (
              <Square />
            ))}
        </td>
        <td>{this.props.conciliation.date}</td>
        <td>{this.props.conciliation.payDate}</td>
        <td>{this.props.conciliation.cardType}</td>
        <td>
          {formatCurrency(
            this.props.conciliation.amountCents / 100,
            this.props.conciliation.value.symbol
          )}
        </td>
        <td>{this.props.conciliation.installment}</td>
        <td>{this.props.conciliation.voucher}</td>
        <td>{this.props.conciliation.batchNumber}</td>
        <td>
          {this.props.conciliation.card.substr(
            this.props.conciliation.card.length - 4
          )}
        </td>
        <td>{this.props.conciliation.shopName}</td>
        <td>{this.props.conciliation.description}</td>
      </tr>
    );
  }
}
/* eslint-enable no-eq-null,eqeqeq */

ConciliationsTableRow.propTypes = {
  conciliation: PropTypes.object,
  disabled: PropTypes.bool,
  onSelectChange: PropTypes.func,
  selected: PropTypes.bool,
};

function TableHead({ sortable, sortColumn, sorting, children, onSort }) {
  const handleClick = () => {
    if (!sortable) {
      return;
    }
    const sort = { column: sortColumn };
    if (sortColumn === sorting.column) {
      sort.direction = sorting.direction === 'asc' ? 'desc' : 'asc';
    } else {
      sort.direction = 'desc';
    }
    onSort(sort);
  };

  return (
    <th className={sortable ? 'sortable' : ''} onClick={handleClick}>
      <div>
        <span>{children}</span>
        {sortable &&
          (sorting.column === sortColumn ? (
            sorting.direction === 'asc' ? (
              <SortUp className="sort-selected" />
            ) : (
              <SortDown className="sort-selected" />
            )
          ) : (
            <SortDown />
          ))}
      </div>
    </th>
  );
}

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
  onSort: PropTypes.func,
  sortColumn: PropTypes.string,
  sorting: PropTypes.object,
};

function ConciliationsTable({
  loading,
  onSortChange,
  sorting,
  data,
  disabled,
  onSelectChange,
  selectedItems,
}) {
  return (
    <StyledTable loading={loading}>
      <thead>
        <tr>
          <th />
          <TableHead
            onSort={onSortChange}
            sortColumn="date"
            sortable
            sorting={sorting}
          >
            Fecha de Venta
          </TableHead>
          <TableHead
            onSort={onSortChange}
            sortColumn="pay_date"
            sortable
            sorting={sorting}
          >
            Fecha de Cobro
          </TableHead>
          <TableHead>Tarjeta</TableHead>
          <TableHead>Monto en AR$</TableHead>
          <TableHead>Cuotas</TableHead>
          <TableHead>Cupón</TableHead>
          <TableHead>Lote</TableHead>
          <TableHead>Ult. 4 Num. Tarjeta</TableHead>
          <TableHead>Comercio</TableHead>
          <TableHead>Descripción</TableHead>
        </tr>
      </thead>

      <tbody>
        {data.map((row, i) => {
          return (
            <ConciliationsTableRow
              conciliation={row}
              disabled={disabled}
              key={i}
              onSelectChange={onSelectChange.bind(this, row)}
              selected={!!selectedItems.find((i) => i.id === row.id)}
            />
          );
        })}
      </tbody>
    </StyledTable>
  );
}

ConciliationsTable.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSelectChange: PropTypes.func,
  onSortChange: PropTypes.func,
  selectedItems: PropTypes.array,
  sorting: PropTypes.object,
};

export default ConciliationsTable;
