import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { PopupboxContainer } from 'react-popupbox';

import TopNav from './TopNav';
import Footer from './Footer';

const popupboxConfig = {
  style: {
    boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.16)',
    borderRadius: '6px',
    background: 'none',
    border: 'none',
  },
  content: {
    style: {
      padding: 0,
      border: 'none',
    },
  },
};

const Container = styled.div`
  padding: 56px 100px;
  min-height: calc(100vh - 170px);
`;
function Layout({ children }) {
  return (
    <React.Fragment>
      <TopNav />
      <Container>{children}</Container>
      <Footer />
      <PopupboxContainer {...popupboxConfig} />
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array,
  ]),
};

export default Layout;
