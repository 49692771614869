import React from 'react';

export class Login extends React.PureComponent {
  componentDidMount() {
    window.location.assign(process.env.REACT_APP_REDIRECT_URL);
  }

  render() {
    return null;
  }
}

export default Login;
