import {
  deleteProfile,
  getProfile,
  getProfiles,
  postProfile,
  saveProfile,
  validateProfile,
} from '../services/profilesService';
import _ from 'lodash';

const NEW_SCANNING = {
  number: 1,
  scanningFields: [],
};

const NEW_IGNORED_LINE = {
  position: 0,
  value: '',
};

const NEW_PROFILE = {
  name: '',
  reconciliationType: 'movements',
  headersCount: 1,
  deprecationDays: 90,
  stable: false,
  originalStable: false,
  stringToRemove: '',
  delimiter: ',',
  ignoreLines: [],
  informativeFields: [],
  fields: [],
  scannings: [{ ...NEW_SCANNING }],
};

const NEW_FIELD = {
  centsSeparator: '',
  dateFormat: null,
  dataType: 'string',
  fieldALocation: 0,
  fieldBLocation: '',
  comparators: [{ originalValue: 'visa', possibleValues: '' }],
};

const NEW_SCANNING_FIELD = {
  fieldBLocation: '',
  comparationType: '=',
  threshold: 0,
};

const INITIAL_STATE = {
  profiles: [],
  fetching: true,
  profile: _.clone(NEW_PROFILE),
  validating: false,
  validationErrors: {},
  filters: {
    type: '',
  },
};

export function fetchProfiles() {
  return (dispatch, getState) => {
    dispatch({ type: 'PROFILES_REQUEST' });
    const { filters } = getState().profiles;
    return getProfiles(filters).then((profiles) => {
      dispatch({ type: 'PROFILES_FETCHED', profiles });
      dispatch(newProfile());
    });
  };
}

export function fetchProfile(profileId) {
  return (dispatch) => {
    dispatch({ type: 'PROFILE_REQUEST' });

    return getProfile(profileId).then((profile) => {
      dispatch({ type: 'PROFILE_FETCHED', profile });
    });
  };
}

export function setProfile(profile) {
  return (dispatch) => {
    dispatch({ type: 'SET_PROFILE', profile });
  };
}

export function destroyProfile(profileId) {
  return (dispatch) => {
    dispatch({ type: 'PROFILE_REQUEST' });

    return deleteProfile(profileId).then(() => {
      return dispatch(fetchProfiles());
    });
  };
}

export function cloneProfile(profile) {
  return (dispatch) => {
    dispatch({ type: 'PROFILE_REQUEST' });

    return postProfile(profile).then(() => {
      return dispatch(fetchProfiles());
    });
  };
}

export function newProfile() {
  return (dispatch) => dispatch({ type: 'PROFILE_CREATE' });
}

export function profileToggleField(field) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    let fields = profile.fields;
    if (_.find(fields, { fieldBLocation: field })) {
      fields = _.reject(fields, { fieldBLocation: field });
    } else {
      const increasecardFields = getState().fileStructure.increasecardFields[
        profile.reconciliationType
      ];
      const increasecardField = _.find(increasecardFields, { location: field });
      fields = [
        ...fields,
        {
          ...NEW_FIELD,
          fieldBLocation: field,
          dataType: increasecardField ? increasecardField.dataType : 'string',
        },
      ];
    }

    const scannings = [...profile.scannings];
    if (scannings.length) {
      const scanning = { ...scannings[0] };
      const existentScanningField = _.find(
        scanning.scanningFields,
        (f) => f.fieldBLocation === field
      );
      if (existentScanningField) {
        scanning.scanningFields = _.reject(
          scanning.scanningFields,
          (sf) => sf === existentScanningField
        );
      } else {
        scanning.scanningFields = [
          ...scanning.scanningFields,
          { ...NEW_SCANNING_FIELD, fieldBLocation: field },
        ];
      }
      scannings[0] = scanning;
    }

    dispatch(profileValidate({ ...profile, fields }));
    return dispatch({ type: 'PROFILE_UPDATE', changes: { fields, scannings } });
  };
}

export function profileUpdate(field, value) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    if (field !== 'name' && field !== 'deprecationDays' && field !== 'stable') {
      dispatch(profileValidate({ ...profile, [field]: value }));
    }
    return dispatch({ type: 'PROFILE_UPDATE', changes: { [field]: value } });
  };
}

export function profileUpdateReconciliationType(value) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const profile_changes = {
      reconciliationType: value,
      fields: [],
      scannings: [{ ...NEW_SCANNING }],
    };
    dispatch(profileValidate({ ...profile, ...profile_changes }));
    return dispatch({ type: 'PROFILE_UPDATE', changes: profile_changes });
  };
}

export function profileChangeField(field, value) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const fields = profile.fields.map((val) => {
      if (val.fieldBLocation === field) {
        return { ...val, ...value };
      }
      return val;
    });
    dispatch(profileValidate({ ...profile, fields }));
    return dispatch({ type: 'PROFILE_UPDATE', changes: { fields } });
  };
}

export function profileAddScanning() {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const scannings = profile.scannings;
    return dispatch({
      type: 'PROFILE_UPDATE',
      changes: { scannings: [...scannings, NEW_SCANNING] },
    });
  };
}

export function profileAddIgnoredLine() {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const ignoreLines = profile.ignoreLines;
    return dispatch({
      type: 'PROFILE_UPDATE',
      changes: { ignoreLines: [...ignoreLines, NEW_IGNORED_LINE] },
    });
  };
}

export function profileDeleteScanning(idx) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const scannings = profile.scannings.filter((value, i) => {
      return i !== idx;
    });
    return dispatch({ type: 'PROFILE_UPDATE', changes: { scannings } });
  };
}

export function profileDeleteIgnoredLine(idx) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const ignoreLines = profile.ignoreLines.filter((value, i) => {
      return i !== idx;
    });
    return dispatch({ type: 'PROFILE_UPDATE', changes: { ignoreLines } });
  };
}

export function profileToggleRule(scanningIndex, field) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const scannings = [...profile.scannings];
    const scanning = { ...scannings[scanningIndex] };
    if (_.find(scanning.scanningFields, { fieldBLocation: field })) {
      scanning.scanningFields = _.reject(scanning.scanningFields, {
        fieldBLocation: field,
      });
    } else {
      scanning.scanningFields = [
        ...scanning.scanningFields,
        { ...NEW_SCANNING_FIELD, fieldBLocation: field },
      ];
    }
    scannings[scanningIndex] = scanning;
    return dispatch({
      type: 'PROFILE_UPDATE',
      changes: { scannings: [...scannings] },
    });
  };
}

export function profileChangeRule(scanningIndex, field, changes) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const scannings = [...profile.scannings];
    const scanning = { ...scannings[scanningIndex] };
    scanning.scanningFields = scanning.scanningFields.map((val) => {
      if (val.fieldBLocation === field) {
        return { ...val, ...changes };
      }
      return val;
    });
    scannings[scanningIndex] = scanning;
    return dispatch({
      type: 'PROFILE_UPDATE',
      changes: { scannings: [...scannings] },
    });
  };
}

export function profileChangeIgnoreLine(lineIndex, changes) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const ignoreLines = profile.ignoreLines;
    ignoreLines[lineIndex] = { ...profile.ignoreLines[lineIndex], ...changes };
    return dispatch({ type: 'PROFILE_UPDATE', changes: { ignoreLines } });
  };
}

export function profileChangeInformative(fieldName, informative) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    const informativeFields = newInformativeFields(
      profile.informativeFields,
      fieldName,
      informative
    );
    return dispatch({ type: 'PROFILE_UPDATE', changes: { informativeFields } });
  };
}

export function newInformativeFields(
  informativeFields,
  fieldName,
  informative
) {
  if (informative) {
    return informativeFields.concat(fieldName);
  }
  return informativeFields.filter(
    (informativeField) => informativeField !== fieldName
  );
}

export function profileValidate(profile) {
  return (dispatch, getState) => {
    if (profile === null) {
      profile = getState().profiles.profile;
    }
    dispatch({ type: 'PROFILE_VALIDATE_REQUEST' });
    const { s3Key } = getState().newConciliation;
    if (s3Key) {
      return validateProfile(s3Key, profile).then((data) => {
        return dispatch({
          type: 'PROFILE_VALIDATE_REQUEST_FETCHED',
          validationErrors: data.valid ? {} : data.error,
        });
      });
    }
    return dispatch({ type: 'PROFILE_NOT_VALIDATED' });
  };
}

export function profileSave() {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    return saveProfile(profile);
  };
}

const profilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return { ...state, profile: action.profile };
    case 'PROFILES_REQUEST':
      return { ...state, profiles: [], fetching: true };
    case 'PROFILES_SET_FILTERS':
      return { ...state, filters: action.filters };
    case 'PROFILES_FETCHED':
      return { ...state, profiles: action.profiles, fetching: false };
    case 'PROFILE_REQUEST':
      return { ...state, profile: null, fetching: true };
    case 'PROFILE_FETCHED':
      return { ...state, profile: action.profile, fetching: false };
    case 'PROFILE_CREATE':
      return { ...state, profile: _.clone(NEW_PROFILE) };
    case 'PROFILE_NOT_VALIDATED':
      return { ...state, validating: false, validationErrors: {} };
    case 'PROFILE_VALIDATE_REQUEST':
      return { ...state, validating: true, validationErrors: {} };
    case 'PROFILE_VALIDATE_REQUEST_FETCHED':
      return {
        ...state,
        validating: false,
        validationErrors: action.validationErrors,
      };
    case 'PROFILE_UPDATE':
      return { ...state, profile: { ...state.profile, ...action.changes } };
    case 'FILE_UPLOADED':
      return { ...state, s3Key: action.key };
    default:
      return state;
  }
};

export default profilesReducer;
