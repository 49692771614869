import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Card from '../../common/Card';
import LinkButton from '../../common/LinkButton';
import Select from '../../common/Select';
import { Box, Flex } from '@rebass/grid';
import classnames from 'classnames';
import { withNamespaces } from 'react-i18next';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledCard = styled(Card)`
  & .new-profile {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
  & h2 {
    text-align: center;
  }
  &.disabled {
    opacity: 0.5;
  }
`;

const propTypes = {
  disabled: PropTypes.bool,
  masquerading: PropTypes.bool,
  onChange: PropTypes.func,
  onNewProfile: PropTypes.func,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      name: PropTypes.string,
    })
  ),
  selectedProfile: PropTypes.any,
};

function ProfileSelection({
  onChange,
  onNewProfile,
  selectedProfile,
  masquerading,
  disabled,
  profiles,
  t,
}) {
  return (
    <StyledCard className={classnames({ disabled: disabled })}>
      <h2>{t('step_2_choose_or_create_profile')}</h2>
      <Flex>
        <Box m={10} width={masquerading ? 1 / 2 : 1}>
          <StyledSelect
            disabled={disabled}
            extraButtonText={masquerading ? 'Crear nuevo' : ''}
            onExtraButtonClick={onNewProfile}
            onSelect={onChange}
            options={profiles}
            placeholder={t('select')}
            selected={selectedProfile}
          />
        </Box>
        {masquerading && (
          <Box m={10} width={1 / 2}>
            <LinkButton
              className="new-profile"
              disabled={disabled}
              href=""
              onClick={onNewProfile}
            >
              {t('create_profile')}
            </LinkButton>
          </Box>
        )}
      </Flex>
    </StyledCard>
  );
}

ProfileSelection.propTypes = propTypes;

export default withNamespaces()(ProfileSelection);
