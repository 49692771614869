import jwtDecode from 'jwt-decode';
import { getUrlParams } from '../helpers';

export function setCredentials(credentials) {
  localStorage.setItem('credentials', JSON.stringify(credentials));
}

export function getCredentials() {
  const credentials = JSON.parse(localStorage.getItem('credentials')) || {};
  const params = getUrlParams();
  if (params['auth_token']) {
    credentials.authToken = params['auth_token'];
  }
  if (params['masquerade_token']) {
    credentials.masqueradeToken = params['masquerade_token'];
  }
  setCredentials(credentials);
  return credentials;
}

export function isAuthenticated() {
  const token = getCredentials().authToken;
  if (token === undefined) {
    return false;
  }
  const decodedToken = jwtDecode(token);
  return decodedToken.expiration_date > Date.now() / 1000;
}
