import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp } from 'styled-icons/fa-solid';

const StyledDiv = styled.div`
  border: 1px solid #ededed;
  height: 33px;
  width: 48px;
  position: relative;
  padding-right: 18px;
  box-sizing: border-box;
  .number-input {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    color: #243568;
    padding-left: 7px;
    box-sizing: border-box;
    outline: none;
  }
  button {
    border: none;
    border-left: 1px solid #ededed;
    background-color: white;
    position: absolute;
    width: 18px;
    height: 50%;
    padding: 0;
    outline: none;

    svg {
      width: 7px;
      height: 100%;
      color: #ededed;
    }
  }
  button.up {
    top: 0;
    right: 0;
    border-bottom: 1px solid #ededed;
  }
  button.down {
    bottom: 0;
    right: 0;
  }
`;

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputValue: props.value };
  }

  onInputChange(e) {
    if (e.target.validity.valid) {
      this.setState({ inputValue: e.target.value });
    }
  }

  commitChange() {
    this.props.onChange(parseFloat(this.state.inputValue));
  }

  setValue(val) {
    this.setState({ inputValue: Math.max(val, this.props.min) });
    this.commitChange();
  }

  render() {
    return (
      <StyledDiv className={this.props.className}>
        <input
          className="number-input"
          disabled={this.props.disabled}
          onBlur={this.commitChange.bind(this)}
          onChange={this.onInputChange.bind(this)}
          pattern="[0-9]*(\.[0-9]*)?"
          step={this.props.step}
          type="text"
          value={this.state.inputValue}
        />
        <button
          className="up"
          disabled={this.props.disabled}
          onClick={this.setValue.bind(
            this,
            this.state.inputValue + this.props.step
          )}
        >
          <ChevronUp />
        </button>
        <button
          className="down"
          disabled={this.props.disabled}
          onClick={this.setValue.bind(
            this,
            this.state.inputValue - this.props.step
          )}
        >
          <ChevronDown />
        </button>
      </StyledDiv>
    );
  }
}

NumberInput.defaultProps = {
  step: 1,
};

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  min: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.number,
};

export default NumberInput;
