import {
  getCredentials,
  setCredentials,
} from '../services/authenticationService';

const INITIAL_STATE = {
  credentials: getCredentials(),
};

export function logout() {
  return (dispatch) => {
    setCredentials(null);
    window.location.reload();
    return dispatch;
  };
}

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default notificationsReducer;
