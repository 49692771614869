import styled from 'styled-components';
import React from 'react';
import { Box, Flex } from '@rebass/grid';
import { Calendar } from 'styled-icons/fa-regular';
import DateRangePicker from 'react-daterange-picker';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  border: 1px solid #ededed;
  background-color: white;
  border-radius: 3px;
  height: 33px;
  cursor: pointer;

  & > * {
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .desde-hasta {
    color: #a3a3a3;
    font-weight: 600;
    font-size: 8px;
    display: block;
  }
  .selected-date {
    color: #223368;
    font-size: 13px;
    display: block;
  }
  .horizontal-separator {
    height: 22px;
    width: 1px;
    background-color: #ededed;
  }
  .calendar-container {
    width: 650px;
    border: 1px solid #ededed;
    background-color: white;
    border-radius: 3px;
    display: block;
    height: auto;
    ${({ alignment }) => {
      switch (alignment) {
        case 'left':
          return 'margin-left: -200%';
        case 'right':
          return 'margin-left: 0';
        default:
          return 'margin-left: -100';
      }
    }};
    margin-top: 6px;
    position: relative;
    z-index: 1;

    * {
      font-family: Open Sans, sans-serif;
    }
  }
`;

const CalendarIcon = styled(Calendar)`
  width: 18px;
  height: 20px;
  color: #223368;
`;

class DatePicker extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      active: false,
    };
  }

  onToggle = () => {
    this.setState((prevState) => ({ active: !prevState.active }));
  };

  handleSelect = (value) => {
    this.setState({ active: false });
    this.props.onSelect(value);
  };

  handleClickOutside() {
    this.setState({ active: false });
  }

  render() {
    return (
      <StyledDiv {...this.props}>
        <Flex onClick={this.onToggle}>
          <Box>
            <span className="desde-hasta">DESDE</span>
            <span className="selected-date">
              {this.props.value.start.format('DD/MM/YYYY')}
            </span>
          </Box>
          <div className="horizontal-separator" />
          <Box>
            <span className="desde-hasta">HASTA</span>
            <span className="selected-date">
              {this.props.value.end.format('DD/MM/YYYY')}
            </span>
          </Box>
          <Box css={{ height: '20px' }}>
            <CalendarIcon />
          </Box>
        </Flex>

        {this.state.active && (
          <div className="calendar-container">
            <DateRangePicker
              locale="es"
              maximumDate={new Date()}
              numberOfCalendars={2}
              onSelect={this.handleSelect}
              value={this.props.value}
            />
          </div>
        )}
      </StyledDiv>
    );
  }
}

DatePicker.propTypes = {
  alignment: PropTypes.oneOf(['left', 'right', 'center']),
  onSelect: PropTypes.func,
  value: PropTypes.any,
};

DatePicker.defaultProps = {
  alignment: 'center',
};

export default onClickOutside(DatePicker);
