import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';
import PropTypes from 'prop-types';

const StyledTable = styled.table`
  width: 100%;
  background-color: #ededed;
  border-spacing: 1px;

  & th,
  & td {
    padding: 15px;
    font-family: 'Open Sans', sans-serif;
    color: #243568;
    font-size: 13px;
  }
  & th {
    font-weight: bold;
    background-color: #f2f6f7;
  }
  & td {
    font-weight: 600;
  }
  & tr:nth-child(odd) td {
    background-color: white;
  }
  & tr:nth-child(even) td {
    background-color: #f7f7f7;
  }
`;

function LoadingOverlay() {
  const LoadingOverlayWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
  `;
  return (
    <LoadingOverlayWrapper>
      <Spinner />
    </LoadingOverlayWrapper>
  );
}

function Table({ className, children, loading }) {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {loading && <LoadingOverlay />}
      <StyledTable className={className}>{children}</StyledTable>
    </div>
  );
}

Table.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  loading: PropTypes.bool,
};

export default Table;
