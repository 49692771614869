import FileUpload from '../components/common/FileUpload/FileUpload';
import conciliationService from './../services/conciliationService';

const NEW_CONCILIATION_INITIAL_STATE = {
  fileUploadStatus: FileUpload.EMPTY,
  fileUploadProgress: 0,
  file: null,
  s3Key: null,
  profileId: null,
  profiles: [],
  simulate: false,
};

export function uploadFile(file, errHandler) {
  return (dispatch) => {
    dispatch({ type: 'FILE_DROPPED', file });
    dispatch({ type: 'UPLOAD_STARTED' });
    const uploadPromise = conciliationService
      .uploadFile(file, (progress) => {
        dispatch({ type: 'UPLOAD_PROGRESS', progress });
      })
      .then((key) => {
        dispatch({ type: 'UPLOAD_COMPLETE', key });
      });

    if (errHandler) {
      return uploadPromise.catch(errHandler);
    }
    return uploadPromise;
  };
}

export function fetchProfiles() {
  return (dispatch) => {
    return conciliationService.getProfiles({}).then((profiles) => {
      dispatch({ type: 'SET_PROFILES', profiles });
    });
  };
}

export function notifyUpload() {
  return (dispatch, getState) => {
    const {
      s3Key,
      file,
      profileId,
      profiles,
      simulate,
    } = getState().newConciliation;
    const profile = profiles.find((p) => p.id === profileId);

    return conciliationService.notifyUpload(
      s3Key,
      file.name,
      profile.name,
      simulate
    );
  };
}

const newConciliationReducer = (
  state = NEW_CONCILIATION_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case 'CLEAR_FILE_UPLOAD':
      return { ...state, fileUploadStatus: FileUpload.EMPTY, s3Key: null };
    case 'SET_PROFILE': {
      const existentProfile = state.profiles.find(
        (profile) => profile.id === action.profileId
      );
      const stable = existentProfile && existentProfile.stable;
      return {
        ...state,
        profileId: action.profileId,
        simulate: !stable,
        lockedSimulate: !stable,
      };
    }
    case 'SET_SIMULATE':
      return { ...state, simulate: action.simulate };
    case 'SET_PROFILES':
      return { ...state, profiles: action.profiles };
    case 'FILE_DROPPED':
      return { ...state, file: action.file };
    case 'RETRY_UPLOAD':
      return {
        ...state,
        file: null,
        profile: null,
        fileUploadStatus: FileUpload.EMPTY,
        fileUploadProgress: 0,
      };
    case 'UPLOAD_STARTED':
      return {
        ...state,
        fileUploadStatus: FileUpload.UPLOADING,
        fileUploadProgress: 0,
      };
    case 'UPLOAD_PROGRESS':
      return { ...state, fileUploadProgress: action.progress };
    case 'UPLOAD_COMPLETE':
      return {
        ...state,
        fileUploadStatus: FileUpload.COMPLETED,
        s3Key: action.key,
      };
    case 'UPLOAD_ERROR':
      return { ...state, fileUploadStatus: FileUpload.UPLOAD_ERROR };
    case 'FILE_TYPE_ERROR':
      return { ...state, fileUploadStatus: FileUpload.FILE_TYPE_ERROR };
    default:
      return state;
  }
};

export default newConciliationReducer;
