import client from './apiClient';
import { isBlank } from './../helpers';

export function getReports(page, { name, type }) {
  const params = { page };

  if (!isBlank(name)) {
    params.name = name;
  }
  if (type) {
    params.data_type = type;
  }

  return client.get('/reports', { params });
}

export function getReportLink(id) {
  return client
    .get(`/reports/${id}/download_link`)
    .then((res) => res.data['download_link']);
}

export function notifyReportDownload(id) {
  return client.post(`/reports/${id}/notify_download`);
}
