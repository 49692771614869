import React from 'react';
import Spinner from './Spinner';
import { Flex } from '@rebass/grid';

function FullPageSpinner() {
  return (
    <Flex alignItems="center" justifyContent="center" py={200}>
      <Spinner />
    </Flex>
  );
}

export default FullPageSpinner;
