import axios from 'axios';
import { getCredentials } from './authenticationService';

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  'https://private-anon-81da470ac8-increaseconciliationapi.apiary-mock.com';

const client = axios.create({
  baseURL: `${API_BASE_URL}/api/v1`,
  transformRequest: [
    (data, headers) => {
      if (data && data.get && data.get('x-amz-credential')) {
        return data;
      }
      const credentials = getCredentials();
      headers['Authorization'] = credentials.authToken;
      if (credentials.masqueradeToken) {
        headers['X-Masqueraded'] = credentials.masqueradeToken;
      }
      return JSON.stringify(data);
    },
  ],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location = '/login';
      return null;
    }
    return Promise.reject(error);
  }
);

export default client;
