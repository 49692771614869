import React, { Component } from 'react';
import { Box, Flex } from '@rebass/grid';
import Paginator from '../../common/Paginator';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import styled from 'styled-components';
import Toggle from '../../common/Toggle';
import Button from '../../common/Button';
import DatePicker from '../../common/DatePicker';
import PaymentsTable from './PaymentsTable';
import {
  paymentSelected,
  fetchPayments,
  filterPayments,
  selectPayment,
  sort,
} from '../../../reducers/paymentsReducer';
import { fetchIncreaseData } from '../../../reducers/increaseDataReducer';
import FullPageSpinner from '../../common/FullPageSpinner';
import RangeInput from '../../common/RangeInput';
import ComponentLabel from '../../common/ComponentLabel';
import TextInput from '../../common/TextInput';
import _ from 'lodash';

const Page = styled.div`
  & .messages {
    font-family: Lato, sans-serif;
    font-size: 15px;
    color: #000000;
  }
  & .legend {
    font-family: Lato, sans-serif;
    font-size: 20px;
    color: #707070;
  }
  & .filter-bar {
    margin-top: 20px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    padding: 13px 0;
    align-items: center;
    justify-content: space-between;
    .vertical-separator {
      height: 35px;
      width: 1px;
      background-color: #ededed;
    }
    * > button {
      width: 100%;
    }
  }
`;

class Payments extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchPayments());
    dispatch(fetchIncreaseData());
  }

  filtersFilled(filters) {
    return (
      !_.isNull(filters.range.min) ||
      !_.isNull(filters.range.max) ||
      !!filters.stablishment ||
      !!filters.provider
    );
  }

  paymentsExists(payments) {
    return payments.length > 0;
  }
  _renderData() {
    const { payments, filters } = this.props;

    if (this.paymentsExists(payments)) {
      return this._renderTable();
    }

    if (!this.paymentsExists(payments) && this.filtersFilled(filters)) {
      return this._renderEmptyStateWithFiltersMessage();
    }

    return this._renderEmptyStateMessage();
  }

  _renderTable() {
    const {
      t,
      selectedItems,
      payments,
      fetching,
      onSelectChange,
      onSortChange,
      sorting,
      page,
      pages,
      onPageSelect,
      filters,
      filterPayments,
    } = this.props;

    return (
      <div>
        <Flex className="messages" justifyContent="space-between">
          <Box>
            <span className="legend">
              {`${t('payments.selected_rows')} ${
                Object.keys(selectedItems).length
              }`}
            </span>
          </Box>
        </Flex>
        <Flex mt={20}>
          <PaymentsTable
            data={payments}
            filters={filters}
            loading={fetching}
            onFilter={filterPayments}
            onSelectChange={onSelectChange}
            onSortChange={onSortChange}
            selectedItems={selectedItems}
            sorting={sorting}
          />
        </Flex>
        <Flex justifyContent="flex-end" mb="17px" mt="17px">
          <Paginator
            onPageSelect={onPageSelect}
            pages={pages}
            selected={page}
          />
        </Flex>
      </div>
    );
  }

  _renderEmptyStateWithFiltersMessage() {
    return (
      <Flex justifyContent="center">
        <Box my={15}>
          <span className="messages">
            {`Los filtros no arrojan resultados.`}
          </span>
        </Box>
      </Flex>
    );
  }

  _renderEmptyStateMessage() {
    return (
      <Flex justifyContent="center">
        <Box my={15}>
          <span className="messages">
            {`En esta pantalla podras visualizar las liquidaciones pendientes y conciliarlas manualmente.`}
          </span>
        </Box>
      </Flex>
    );
  }

  render() {
    const {
      t,
      fetchingIncreaseData,
      filterPayments,
      filters,
      onPaymentClick,
      selectedItems,
    } = this.props;

    if (fetchingIncreaseData) {
      return <FullPageSpinner />;
    }
    return (
      <Page>
        <Flex>
          <Box>
            <h1>{t('payments.title')}</h1>
          </Box>
        </Flex>
        <Flex className="filter-bar">
          <Box>
            <Toggle
              checked={filters.showAll}
              onChange={(value) => filterPayments('showAll', value)}
            >
              {t('pending_operations.show_all')}
            </Toggle>
          </Box>
          <div className="vertical-separator" />
          <Box width="180px">
            <ComponentLabel label={t('payments.filters.date_range')}>
              <DatePicker
                alignment={'right'}
                onSelect={(value) => filterPayments('dateRange', value)}
                value={filters.dateRange}
              />
            </ComponentLabel>
          </Box>
          <Box>
            <ComponentLabel label={t('payments.filters.search_by')}>
              <TextInput
                onChange={(value) => filterPayments('stablishment', value)}
                placeholder={t('payments.filters.inst_number')}
                value={filters.stablishment}
              />
            </ComponentLabel>
          </Box>
          <Box>
            <ComponentLabel label={t('payments.filters.amount')}>
              <RangeInput
                maxPlaceholder={t('max')}
                minPlaceholder={t('min')}
                onChange={(value) => filterPayments('range', value)}
                value={filters.range}
              />
            </ComponentLabel>
          </Box>
          <Box>
            <Button
              disabled={selectedItems.length === 0}
              onClick={onPaymentClick}
            >
              {t('payments.reconcile')}
            </Button>
          </Box>
        </Flex>
        {this._renderData()}
      </Page>
    );
  }
}

Payments.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  filterPayments: (filter, value) => {
    return dispatch(filterPayments({ [filter]: value }));
  },
  onPageSelect: (page) => {
    dispatch({ type: 'PAYMENTS_SET_PAGE', page });
    return dispatch(fetchPayments());
  },
  onSelectChange: (item, selected) => dispatch(selectPayment(item, selected)),
  onPaymentClick: () => dispatch(paymentSelected()),
  onSortChange: (sorting) => dispatch(sort(sorting)),
});

function mapStateToProps(state) {
  return {
    ...state.payments,
    shops: state.increaseData.shops,
    cards: state.increaseData.cards,
    fetchingIncreaseData: state.increaseData.fetching,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Payments));
