import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@rebass/grid';

const StyledDiv = styled.div`
  width: 180px;
  box-sizing: border-box;
  div {
    align-items: center;
  }
  input {
    padding: 9px;
    height: 33px;
    border: 1px solid #ededed;
    border-radius: 3px;
    width: 81px;
    font-family: 'Open Sans', sans-serif;
    color: #243568;
    box-sizing: border-box;
    outline: none;
  }
  div.separator {
    width: 9px;
    height: 1px;
    background-color: #b1b1b1;
    margin: 0 2px;
  }
`;

class RangeInput extends React.Component {
  onMinChange(e) {
    if (e.target.validity.valid) {
      this.props.onChange({
        min: e.target.value ? parseFloat(e.target.value) : null,
        max: this.props.value.max,
      });
    }
  }

  onMaxChange(e) {
    if (e.target.validity.valid) {
      this.props.onChange({
        min: this.props.value.min,
        max: e.target.value ? parseFloat(e.target.value) : null,
      });
    }
  }

  render() {
    const {
      value,
      disabled,
      minPlaceholder,
      maxPlaceholder,
      className,
    } = this.props;
    return (
      <StyledDiv className={className}>
        <Flex>
          <Box>
            <input
              disabled={disabled}
              onChange={this.onMinChange.bind(this)}
              pattern="[0-9]*(\.[0-9]*)?"
              placeholder={minPlaceholder}
              type="text"
              value={value.min === null ? '' : value.min}
            />
          </Box>
          <Box>
            <div className="separator" />
          </Box>
          <Box>
            <input
              disabled={disabled}
              onChange={this.onMaxChange.bind(this)}
              pattern="[0-9]*(\.[0-9]*)?"
              placeholder={maxPlaceholder}
              type="text"
              value={value.max === null ? '' : value.max}
            />
          </Box>
        </Flex>
      </StyledDiv>
    );
  }
}

RangeInput.defaultProps = {};

RangeInput.propTypes = {
  disabled: PropTypes.bool,
  maxPlaceholder: PropTypes.string,
  minPlaceholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default RangeInput;
