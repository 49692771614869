import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';
import { Minus, Plus } from 'styled-icons/fa-solid';
import Select from '../../common/Select';
import LinkButton from '../../common/LinkButton';
import MoneyInput from '../../common/MoneyInput';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import NumberInput from '../../common/NumberInput';

const StyledDiv = styled.div`
  width: 100%;
  .scanning {
    background-color: #f2f6f7;
    border: 1px solid #ededed;
    padding: 27px;
    margin: 15px 0;
    position: relative;
    min-height: 40px;
    .scanning-number {
      position: absolute;
      font-size: 13px;
      color: white;
      padding: 8px 17px;
      background-color: #243568;
      border-bottom-left-radius: 6px;
      top: 0;
      right: 0;
    }
    .rule {
      margin: 10px 0;
      button.rule-toggle {
        width: 20px;
        height: 20px;
        background-color: #ededed;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 50%;
        padding: 0;
        cursor: pointer;
        svg {
          width: 8px;
          height: 8px;
          display: block;
          margin: auto;
        }
      }
      span {
        font-size: 13px;
      }
      .rule-name {
        margin-right: 7px;
        min-width: 120px;
      }
      .comparison-type {
        width: 160px;
      }
      .range {
        width: 66px;
        margin-left: 6px;
      }
      .range.money {
        width: 99px;
      }
    }
    .rule.disabled {
      span,
      select,
      .comparison-type,
      input {
        opacity: 0.3;
      }
    }
  }
`;

const DeleteScanningButton = styled(LinkButton)`
  color: #ff3b3b;
  font-size: 15px;
  position: absolute;
  bottom: 19px;
  right: 17px;
`;

const NewScanningButton = styled(LinkButton)`
  font-size: 15px;
  margin-top: 10px;
`;

const StyledPlus = styled(Plus)`
  color: #3b86ff;
`;

const StyledMinus = styled(Minus)`
  color: #b52727;
`;

class Rule extends React.Component {
  onKeyChange(key, value) {
    this.props.onChange({ [key]: value });
  }

  onThreshold(value) {
    this.props.onChange({ threshold: value });
  }

  fieldByType() {
    switch (this.props.value.type) {
      case 'money':
        return (
          <MoneyInput
            className="range money"
            disabled={this.props.disabled}
            min={0}
            onChange={this.onThreshold.bind(this)}
            value={this.props.value.threshold}
          />
        );
      case 'long':
      case 'integer':
      case 'date':
        return (
          <NumberInput
            className="range"
            disabled={this.props.disabled}
            min={0}
            onChange={this.onThreshold.bind(this)}
            value={this.props.value.threshold}
          />
        );
      default:
        throw new Error(`Unknown field type: ${this.props.value.type}`);
    }
  }

  extraField() {
    const field = this.props.value;
    if (
      field &&
      this._comparesAgainstThresholdByComparationType(field.comparationType) &&
      this._comparesAgainstThresholdByType(field.type)
    ) {
      return <Box>{this.fieldByType()}</Box>;
    }
    return null;
  }

  _comparesAgainstThresholdByComparationType(comparationType) {
    return comparationType === 'range';
  }

  _comparesAgainstThresholdByType(type) {
    return ['money', 'integer', 'long', 'date'].includes(type);
  }

  comparationTypesForField() {
    const comparationTypes = this.props.comparationTypes;
    const field = this.props.value;

    if (!field || this._comparesAgainstThresholdByType(field.type)) {
      return comparationTypes;
    }
    return comparationTypes.filter((comparationType) => {
      return !this._comparesAgainstThresholdByComparationType(
        comparationType.value
      );
    });
  }

  _comparationType() {
    const comparationType =
      this.props.value && this.props.value.comparationType;
    const comparationTypesForFields = this.comparationTypesForField();

    const exists = _.find(comparationTypesForFields, {
      value: comparationType,
    });

    if (exists) {
      return comparationType;
    }
    return '=';
  }

  render() {
    const disabled = !this.props.value;
    return (
      <Box className={classnames({ rule: true, disabled })} width="500px">
        <Flex alignItems="center">
          {!this.props.disabled && (
            <Box mr="8px">
              <button
                className="rule-toggle"
                disabled={this.props.disabled}
                onClick={this.props.onToggle}
              >
                {disabled ? <StyledPlus /> : <StyledMinus />}
              </button>
            </Box>
          )}
          <Box className="rule-name">
            <span>{this.props.name}</span>
          </Box>
          <Box>
            <Select
              className="comparison-type"
              disabled={disabled || this.props.disabled}
              onSelect={this.onKeyChange.bind(this, 'comparationType')}
              options={this.comparationTypesForField()}
              selected={this._comparationType()}
            />
          </Box>
          {disabled ? null : this.extraField()}
        </Flex>
      </Box>
    );
  }
}

Rule.propTypes = {
  comparationTypes: PropTypes.array,
  disabled: PropTypes.bool,
  name: PropTypes.string,

  onChange: PropTypes.func,
  onToggle: PropTypes.func,
  value: PropTypes.object,
};

class Scanning extends React.Component {
  getRuleName(field) {
    const increaseCardField = _.find(this.props.increasecardFields, {
      value: field.fieldBLocation,
    });
    return increaseCardField ? increaseCardField.name : field.fieldBLocation;
  }

  getValueForRule(field) {
    const scanningField = _.find(this.props.value.scanningFields, {
      fieldBLocation: field.fieldBLocation,
    });
    if (scanningField) {
      return { ...scanningField, type: field.dataType };
    }
    return null;
  }

  render() {
    return (
      <div className="scanning">
        <div className="scanning-number">
          Barrido N°
          {this.props.idx + 1}
        </div>
        {!this.props.disabled && (
          <DeleteScanningButton onClick={this.props.onDelete}>
            Eliminar barrido
          </DeleteScanningButton>
        )}
        <Flex flexWrap="wrap">
          {this.props.fields.map((val, i) => (
            <Rule
              comparationTypes={this.props.comparationTypes}
              disabled={this.props.disabled}
              key={i}
              name={this.getRuleName(val)}
              onChange={this.props.onChange.bind(null, val.fieldBLocation)}
              onToggle={this.props.onToggleRule.bind(null, val.fieldBLocation)}
              value={this.getValueForRule(val)}
            />
          ))}
        </Flex>
      </div>
    );
  }
}

Scanning.propTypes = {
  comparationTypes: PropTypes.array,
  disabled: PropTypes.bool,
  fields: PropTypes.array,
  idx: PropTypes.number,
  increasecardFields: PropTypes.array,
  onChange: PropTypes.func,

  onDelete: PropTypes.func,
  onToggleRule: PropTypes.func,
  value: PropTypes.object,
};

function MatchRules({
  value,
  fields,
  increasecardFields,
  comparationTypes,
  onDeleteScanning,
  onRuleChange,
  onToggleRule,
  disabled,
  onAddScanning,
}) {
  return (
    <StyledDiv>
      {value.map((val, i) => (
        <Scanning
          comparationTypes={comparationTypes}
          disabled={disabled}
          fields={fields}
          idx={i}
          increasecardFields={increasecardFields}
          key={i}
          onChange={onRuleChange.bind(null, i)}
          onDelete={onDeleteScanning.bind(null, i)}
          onToggleRule={onToggleRule.bind(null, i)}
          value={val}
        />
      ))}
      {!disabled && (
        <NewScanningButton onClick={onAddScanning}>
          Agregar otro Barrido
        </NewScanningButton>
      )}
    </StyledDiv>
  );
}

MatchRules.propTypes = {
  comparationTypes: PropTypes.array,
  disabled: PropTypes.bool,
  fields: PropTypes.array,
  increasecardFields: PropTypes.array,

  onAddScanning: PropTypes.func,
  onDeleteScanning: PropTypes.func,
  onRuleChange: PropTypes.func,
  onToggleRule: PropTypes.func,
  value: PropTypes.array,
};

export default MatchRules;
