import React from 'react';
import styled from 'styled-components';
import Card from '../../common/Card';
import { Box, Flex } from '@rebass/grid';
import doubleCheckmark from './double-checkmark.png';
import { Close, FileDownload } from 'styled-icons/material';
import LinkButton from '../../common/LinkButton';
import { PopupboxManager } from 'react-popupbox';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withNamespaces } from 'react-i18next';

const StyledCard = styled(Card)`
  width: 693px;
  background: none;
  padding: 0;
  overflow: hidden;
  border: none;
  border-radius: 6px;
  position: relative;

  & > .top-container {
    padding: 23px;
    background-color: #465253;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.06);
  }

  & > .top-container h1 {
    border-bottom: 1px solid rgba(1, 1, 1, 0.19);
    text-align: center;
  }

  & > .top-container h1.title {
    padding-bottom: 19px;
    margin: 0;
    font-family: Lato, sans-serif;
    font-size: 28px;
    color: white;
  }

  & > .top-container h1.percentage {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 42px;
    color: #00dd00;
    font-weight: bold;
  }

  & .subtitle {
    margin-top: 10px;
  }

  & .double-checkmark {
    width: 40px;
    height: 24px;
    margin-right: 17px;
  }

  & > .top-container p {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 15px;
    color: white;
    text-align: left;
  }

  & > .bottom-container {
    background-color: #f7f7f7;
  }

  & > .bottom-container > div {
    background-color: white;
    margin: 21px 21px 21px 0;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
  }
  & > .bottom-container > div:first-child {
    margin-left: 21px;
  }

  & > .bottom-container .entries-container {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    color: #465253;
    padding: 22px;
    text-align: left;
  }

  & > .bottom-container ol {
    list-style: none;
    margin: 0;
    padding-left: 5px;
    opacity: 0.7;
    font-size: 12px;
  }

  & > .bottom-container .download-link-container {
    padding: 22px;
    text-align: center;
  }

  & > .bottom-container .download-link-container > p {
    font-size: 13px;
    font-weight: bold;
    color: #43425d;
    margin: 5px 0;
  }
`;

const DownloadIcon = styled(FileDownload)`
  width: 22px;
  height: 22px;
  color: #00dd00;
`;

const CloseButton = styled(LinkButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  & > svg {
    width: 16px;
    height: 16px;
    color: white;
  }
`;

function DetailPopup({ report, onDownloadClicked, t }) {
  const totalEntries = report.conciliationResults.processedLines;
  const reconciledLines = report.conciliationResults.scannings.reduce(
    (acc, val) => acc + val.reconciledLines,
    0
  );
  const nonReconciledLines = totalEntries - reconciledLines;
  const sortedScannings = _.sortBy(
    report.conciliationResults.scannings,
    'scanningNumber'
  );

  const ordinal = [
    t('first'),
    t('second'),
    t('third'),
    t('fourth'),
    t('fifth'),
    t('sixth'),
    t('seventh'),
    t('eighth'),
    t('nineth'),
  ];

  return (
    <StyledCard>
      <CloseButton onClick={PopupboxManager.close}>
        <Close />
      </CloseButton>
      <Flex className="top-container" justifyContent="center">
        <Box width={2 / 5}>
          <h1 className="title">{t('results')}</h1>
          <h1 className="percentage">
            {((reconciledLines * 100) / totalEntries).toFixed(1)}%
          </h1>
          <Flex alignItems="center" className="subtitle">
            <Box height="24px">
              <img alt="" className="double-checkmark" src={doubleCheckmark} />
            </Box>
            <Box>
              <p>{t('conciliated_operations')}</p>
              <p>
                {totalEntries} {t('analized_records')}
              </p>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Flex className="bottom-container">
        <Box className="entries-container" width={7 / 12}>
          <span>
            <b>{t('conciliated_entries')}</b>
            {reconciledLines}
          </span>
          <ol>
            {sortedScannings.map((scanning, idx) => (
              <li key={idx}>
                {((scanning.reconciledLines * 100) / totalEntries).toFixed(1)}%
                {t('in_the')} {ordinal[scanning.scanningNumber - 1]}{' '}
                {t('swept')} ({scanning.reconciledLines} {t('entries')})
              </li>
            ))}
          </ol>
          <span>
            <b>{t('entries_not_reconciled')}</b>
            {nonReconciledLines} (
            {((nonReconciledLines * 100) / totalEntries).toFixed(1)}
            %)
          </span>
        </Box>
        <Box className="download-link-container" width={5 / 12}>
          <DownloadIcon />
          <p>{t('download_conciliation_report')}</p>
          <LinkButton onClick={onDownloadClicked}>CSV</LinkButton>
        </Box>
      </Flex>
    </StyledCard>
  );
}

DetailPopup.propTypes = {
  onDownloadClicked: PropTypes.func,
  report: PropTypes.object,
};

export default withNamespaces()(DetailPopup);
