import styled from 'styled-components';

const ProfileCardContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px #ededed solid;
  border-radius: 4px;
  padding: 13px 22px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 0 50px 0;
  :hover {
    box-shadow: 0px 4px 2px 2px rgba(0, 0, 0, 0.15);
  }
`;

export default ProfileCardContainer;
