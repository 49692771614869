import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'styled-icons/material';
import _ from 'lodash';

const StyledPaginator = styled.div`
  display: flex;
  align-items: center;
`;

const BaseButton = styled.button`
  outline: none;
  cursor: pointer;
  margin: 0 4px;
  border-radius: 2px;
`;

const PrevNextButton = styled(BaseButton)`
  width: 20px;
  height: 20px;
  padding: 0;
  border: 1px solid #ededed;
  background-color: #fafafa;
  box-sizing: content-box;

  :disabled {
    opacity: 0.5;
  }

  & > svg {
    color: #243568;
    width: 20px;
    height: 20px;
  }
`;

const PageButton = styled(BaseButton)`
  background-color: white;
  border: 1px solid #e8e9ec;
  width: 35px;
  height: 35px;
  color: #243568;
  font-family: Open Sans, sans-serif;
  font-size: 13px;

  &.selected {
    font-color: #3b86ff;
    background-color: #f2f6f7;
    border-color: #ededed;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.17);
  }
`;

function Paginator({ selected, pages, onPageSelect }) {
  const npages = pages;
  let from = selected > 2 ? selected - 2 : 1;
  const to = Math.min(from + 4, npages);
  from = Math.max(to - 4, 1);
  const pagenums = _.range(from, to + 1);

  return (
    <StyledPaginator>
      <PrevNextButton
        disabled={selected === 1}
        onClick={onPageSelect.bind(this, selected - 1)}
      >
        <ChevronLeft />
      </PrevNextButton>
      {pagenums.map((n, i) => {
        const className = n === selected ? 'selected' : '';
        return (
          <PageButton
            className={className}
            disabled={selected === n}
            key={i}
            onClick={onPageSelect.bind(this, n)}
          >
            {n}
          </PageButton>
        );
      })}
      <PrevNextButton
        disabled={selected === npages}
        onClick={onPageSelect.bind(this, selected + 1)}
      >
        <ChevronRight />
      </PrevNextButton>
    </StyledPaginator>
  );
}

Paginator.propTypes = {
  onPageSelect: PropTypes.func,
  pages: PropTypes.number,
  selected: PropTypes.number,
};

Paginator.defaultProps = {
  pages: 1,
  selected: 1,
};

export default Paginator;
