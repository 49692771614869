import _ from 'lodash';

export function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });

  return Object.assign({}, ...keyValues);
}

export function isBlank(str) {
  return str.trim().length === 0;
}

export function formatCurrency(number, sign) {
  sign = sign || '$';
  number = parseFloat(number);
  if (isNaN(number)) {
    return `${sign} 0.00`;
  }
  return `${sign} ${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

export function mapArrayForSelect(map, arr) {
  return arr.map((v) => {
    return { value: v, name: _.get(map, v, v) };
  });
}

export function getUrlParams() {
  const s1 = window.location.search
      .substring(1, window.location.search.length)
      .split('&'),
    r = {};
  let s2;
  for (let i = 0; i < s1.length; i += 1) {
    s2 = s1[i].split('=');
    r[decodeURIComponent(s2[0]).toLowerCase()] = decodeURIComponent(s2[1]);
  }
  return r;
}

export function objectKeysToCamelCase(obj) {
  if (!_.isObject(obj)) {
    return obj;
  } else if (_.isArray(obj)) {
    return obj.map((v) => objectKeysToCamelCase(v));
  }
  return _.reduce(
    obj,
    (r, v, k) => {
      return {
        ...r,
        [_.camelCase(k)]: objectKeysToCamelCase(v),
      };
    },
    {}
  );
}
