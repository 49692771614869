import React, { Component } from 'react';
import { Box, Flex } from '@rebass/grid';
import Paginator from '../../common/Paginator';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import styled from 'styled-components';
import Toggle from '../../common/Toggle';
import Select from '../../common/Select';
import Button from '../../common/Button';
import DatePicker from '../../common/DatePicker';
import ConciliationsTable from './ConciliationsTable';
import {
  conciliateSelected,
  deprecateSelected,
  fetchConciliations,
  filterConciliations,
  paginateConciliations,
  selectConciliation,
  sort,
} from '../../../reducers/conciliationsReducer';
import { fetchIncreaseData } from '../../../reducers/increaseDataReducer';
import { formatCurrency, renameKeys } from '../../../helpers';
import FullPageSpinner from '../../common/FullPageSpinner';

const Page = styled.div`
  & .legend {
    font-family: Lato, sans-serif;
    font-size: 20px;
    color: #707070;
  }
  & .filter-bar {
    margin-top: 20px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    height: 52px;
    align-items: center;
    justify-content: space-between;
    .vertical-separator {
      height: 35px;
      width: 1px;
      background-color: #ededed;
    }
    * > button {
      width: 100%;
    }
  }
`;

class Conciliations extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchConciliations());
    dispatch(fetchIncreaseData());
  }

  getConciliationButtonText() {
    if (this.props.selectedItems.length) {
      return formatCurrency(
        this.props.selectedItems.reduce(
          (acc, val) => acc + parseFloat(val.amountCents / 100),
          0
        )
      );
    }
    return null;
  }

  getCardOptions() {
    const { t, cards } = this.props;
    return [
      { value: null, name: t('pending_operations.filters.all_cards') },
    ].concat(
      cards.map((card) => {
        return { value: card, name: t(`general.cards.${card}`) };
      })
    );
  }

  getShopOptions() {
    const { t, shops } = this.props;
    return [
      { value: null, name: t('pending_operations.filters.all_shops') },
    ].concat(shops.map((shop) => renameKeys(shop, { id: 'value' })));
  }

  render() {
    const {
      t,
      conciliations,
      fetching,
      fetchingIncreaseData,
      filterConciliations,
      paginateConciliations,
      filters,
      onConciliateClick,
      onSelectChange,
      onSortChange,
      page,
      pages,
      selectedItems,
      sorting,
      totalPending,
    } = this.props;

    if (fetchingIncreaseData) {
      return <FullPageSpinner />;
    }
    return (
      <Page>
        <Flex>
          <Box>
            <h1>{t('pending_operations.title')}</h1>
          </Box>
        </Flex>
        <Flex>
          <Box>
            <span className="legend">
              {`${t('pending_operations.unconciliated_total')} ${formatCurrency(
                totalPending
              )}`}
            </span>
          </Box>
        </Flex>
        <Flex className="filter-bar">
          <Box>
            <Toggle
              checked={filters.showAll}
              onChange={(value) => filterConciliations('showAll', value)}
            >
              {t('pending_operations.show_all')}
            </Toggle>
          </Box>
          <div className="vertical-separator" />
          <Box width="180px">
            <Select
              onSelect={(value) => filterConciliations('shop', value)}
              options={this.getShopOptions()}
              selected={filters.shop}
            />
          </Box>
          <Box width="180px">
            <Select
              onSelect={(value) => filterConciliations('card', value)}
              options={this.getCardOptions()}
              selected={filters.card}
            />
          </Box>
          <Box width="180px">
            <DatePicker
              onSelect={(value) => filterConciliations('dateRange', value)}
              value={filters.dateRange}
            />
          </Box>
          <div className="vertical-separator" />
          <Box width="178px">
            <Button
              disabled={selectedItems.length === 0}
              onClick={onConciliateClick}
            >
              {t('pending_operations.conciliate')}{' '}
              {this.getConciliationButtonText()}
            </Button>
          </Box>
        </Flex>

        <Flex mt={20}>
          <ConciliationsTable
            data={conciliations}
            loading={fetching}
            onSelectChange={onSelectChange}
            onSortChange={onSortChange}
            selectedItems={selectedItems}
            sorting={sorting}
          />
        </Flex>

        <Flex justifyContent="flex-end" mb="17px" mt="17px">
          <Paginator
            onPageSelect={(page) => paginateConciliations(page)}
            pages={pages}
            selected={page}
          />
        </Flex>
      </Page>
    );
  }
}

Conciliations.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  filterConciliations: (filter, value) => {
    return dispatch(filterConciliations({ [filter]: value }));
  },
  paginateConciliations: (page) => dispatch(paginateConciliations(page)),
  onSelectChange: (item, selected) =>
    dispatch(selectConciliation(item, selected)),
  onConciliateClick: () => dispatch(conciliateSelected()),
  onDeprecateClick: () => dispatch(deprecateSelected()),
  onSortChange: (sorting) => dispatch(sort(sorting)),
});

function mapStateToProps(state) {
  return {
    shops: state.increaseData.shops,
    cards: state.increaseData.cards,
    ...state.conciliations,
    fetchingIncreaseData: state.increaseData.fetching,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Conciliations));
