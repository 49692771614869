import { CaretDown } from 'styled-icons/fa-solid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Popover from 'react-popover';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import Button from './Button';
import ComponentLabel from './ComponentLabel';
import TextInput from './TextInput';
import { withNamespaces } from 'react-i18next';

const StyledPopOverBody = styled(Flex)`
  & > div {
    padding: 12px;
  }
  span {
    font-family: 'Open Sans', sans-serif;
    color: #243568;
    font-size: 13px;
  }
  .separator {
    width: 100%;
    height: 1px;
    background-color: #f1f1f1;
    margin: 0;
    padding: 0;
  }
  .buttons {
    div {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
`;

class FilterPopOverBody extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  handleClickOutside() {
    this.props.onCancel();
  }

  onInputChange(value) {
    this.setState({ value });
  }

  render() {
    const { onSave, onClear, t } = this.props;
    return (
      <StyledPopOverBody flexDirection={'column'}>
        <ComponentLabel label={'Buscar por:'}>
          <TextInput
            onChange={this.onInputChange.bind(this)}
            value={this.state.value}
          />
        </ComponentLabel>
        <Box className="separator" />
        <Box>
          <Flex className={'buttons'} justifyContent={'space-between'}>
            <Box pr={'3px'}>
              <Button onClick={() => onSave(this.state.value)}>
                {t('payments.apply_filter')}
              </Button>
            </Box>
            <Box pl={'3px'}>
              <Button onClick={onClear}>{t('payments.clean_filter')}</Button>
            </Box>
          </Flex>
        </Box>
      </StyledPopOverBody>
    );
  }
}

FilterPopOverBody.propTypes = {
  onCancel: PropTypes.func,
  onClear: PropTypes.func,
  onSave: PropTypes.func,
  value: PropTypes.string,
};

// eslint-disable-next-line no-class-assign
FilterPopOverBody = withNamespaces()(onClickOutside(FilterPopOverBody));

const PopoverContainer = styled.div`
  ${(props) => props.isDirty && `background-color: #80808057; width: 12%;`}
`;

class FilterTableHead extends React.Component {
  state = { isOpen: false, filterValue: this.props.filterValue };

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.filterValue !== props.filterValue) {
      return {
        filterValue: props.filterValue,
      };
    }
    return null;
  }

  toggle(toState = null) {
    this.setState((prevState) => ({
      isOpen: toState === null ? !prevState.isOpen : toState,
    }));
  }

  cancel() {
    this.toggle(false);
  }

  save(value) {
    this.toggle(false);
    this.props.onFilter(value);
  }

  clear() {
    this.props.onFilter('');
    this.cancel();
  }

  render() {
    const { children } = this.props,
      isDirty = Boolean(this.state.filterValue);

    return (
      <th>
        <div>
          <Flex justifyContent={'space-between'}>
            <span>{children}</span>
            <PopoverContainer isDirty={isDirty}>
              <Popover
                body={
                  <FilterPopOverBody
                    onCancel={this.cancel.bind(this)}
                    onClear={this.clear.bind(this)}
                    onSave={this.save.bind(this)}
                    value={this.state.filterValue}
                  />
                }
                isOpen={this.state.isOpen}
                preferPlace={'below'}
              >
                <CaretDown
                  className="ignore-react-onclickoutside"
                  onClick={this.toggle.bind(this, null)}
                />
              </Popover>
            </PopoverContainer>
          </Flex>
        </div>
      </th>
    );
  }
}

FilterTableHead.propTypes = {
  children: PropTypes.node.isRequired,
  filterValue: PropTypes.string,
  onFilter: PropTypes.func,
};

export default FilterTableHead;
