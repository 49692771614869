import { combineReducers } from 'redux';
import authReducer from './authReducer';
import reportsReducer from './reportsReducer';
import newConciliationReducer from './newConciliationReducer';
import conciliationsReducer from './conciliationsReducer';
import paymentsReducer from './paymentsReducer';
import increaseDataReducer from './increaseDataReducer';
import profilesReducer from './profilesReducer';
import notificationsReducer from './notificationsReducer';
import fileStructureReducer from './fileStructureReducer';

export default combineReducers({
  increaseData: increaseDataReducer,
  fileStructure: fileStructureReducer,
  newConciliation: newConciliationReducer,
  reports: reportsReducer,
  conciliations: conciliationsReducer,
  payments: paymentsReducer,
  profiles: profilesReducer,
  notifications: notificationsReducer,
  auth: authReducer,
});
