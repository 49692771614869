import client from './apiClient';

const noop = () => {};

function _getUploadLink() {
  return client
    .get('/files/upload_link', { params: { file_type: 'reconciliation' } })
    .then((res) => {
      return res.data;
    });
}

function _uploadToS3(file, uploadInfo, onProgress) {
  const formData = new FormData();
  formData.append('key', uploadInfo.key);
  formData.append('x-amz-credential', uploadInfo['x-amz-credential']);
  formData.append('x-amz-date', uploadInfo['x-amz-date']);
  formData.append('x-amz-signature', uploadInfo['x-amz-signature']);
  formData.append('x-amz-algorithm', uploadInfo['x-amz-algorithm']);
  if (uploadInfo['x-amz-security-token']) {
    formData.append('x-amz-security-token', uploadInfo['x-amz-security-token']);
  }
  formData.append('policy', uploadInfo.policy);
  formData.append('Expires', uploadInfo['Expires']);
  formData.append(
    'Content-Type',
    uploadInfo['Content-Type'] || 'multipart/form-data'
  );
  formData.append('file', file);

  return client
    .post(uploadInfo['base_url'], formData, {
      headers: {
        'Content-Type': uploadInfo['Content-Type'],
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(percentCompleted);
      },
    })
    .then(() => {
      return uploadInfo.key;
    });
}

function uploadFile(file, onProgress = noop) {
  return _getUploadLink().then((uploadInfo) => {
    return _uploadToS3(file, uploadInfo, onProgress);
  });
}

function getProfiles() {
  return client.get('/reconciliation_profiles').then((res) => {
    return res.data;
  });
}

function notifyUpload(key, filename, reconciliationProfileName, simulate) {
  return client.post('/reconciliations/notify', {
    key,
    filename,
    reconciliation_profile_name: reconciliationProfileName,
    simulate,
  });
}

function getTransactions(page, filters, sorting) {
  return client.get('/increase_transactions', {
    params: { page, ...filters, ...sorting },
  });
}

function conciliate(ids) {
  return client.post('/increase_transactions/conciliate', { ids: ids });
}

function deprecate(ids) {
  return client.post('/increase_transactions/conciliate', {
    params: { deprecate: true, ids },
  });
}

export default {
  uploadFile,
  getProfiles,
  notifyUpload,
  getTransactions,
  conciliate,
  deprecate,
};
