import Table from '../../common/Table';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  border-spacing: 2px;
  border: none;
  width: 100%;
  & tr:nth-child(even) td {
    background-color: white;
  }
  td {
    border: 1px solid #ededed;
    padding: 12px 7px;
  }

  thead {
    tr th {
      text-align: left;
    }
  }

  .field-name-col {
    width: 204px;
  }
  .col-number-col {
    width: 140px;
  }
  .reg-type-detail {
    span {
      display: inline-block;
      font-size: 14px;
      color: #aaaaaa;
      font-weight: normal;
    }
    flex-grow: 1;
  }
  .col-number {
    width: 100%;
    svg {
      color: black;
    }
  }

  tr.disabled td {
    color: #ededed;
    .col-number,
    .reg-type {
      opacity: 0.3;
    }
  }
  .lock-container {
    svg {
      height: 21px;
      color: #ededed;
    }
  }
`;

export default StyledTable;
