import React from 'react';

import { isAuthenticated } from '../../../services/authenticationService';
import { getUsesV2 } from '../../../services/accountService';
import { getUrlParams } from '../../../helpers';
import { Redirect } from 'react-router-dom';
import Layout from '../../layout/Layout';

export class CheckV2AndLogin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    getUsesV2().then((usesV2) => this.setState({ usesV2 }));
  }

  render() {
    const { component: Component } = this.props;
    if (isAuthenticated()) {
      if (this.state.usesV2 === undefined) {
        return null;
      }
      if (this.state.usesV2) {
        return (
          <Redirect
            to={{
              pathname: '/v2',
              state: {
                authToken: getUrlParams().auth_token,
                masqueradeToken: getUrlParams().masquerade_token,
              },
            }}
          />
        );
      }
      return (
        <Layout>
          <Component {...this.props} />
        </Layout>
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }
}

export default CheckV2AndLogin;
