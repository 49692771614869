const INITIAL_STATE = {
  notifications: [
    {
      type: 'error',
      filename: 'Conciliar 1.csv',
      read: false,
      time: 'Hace 1 min',
    },
    {
      type: 'conciliation',
      filename: 'Ventas 3.csv',
      read: true,
      time: 'Hace 4 min',
    },
    {
      type: 'conciliation',
      filename: 'Ventas 2.csv',
      read: true,
      time: 'Hace 4 min',
    },
    {
      type: 'conciliation',
      filename: 'Ventas 1.csv',
      read: true,
      time: 'Hace 4 min',
    },
  ],
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default notificationsReducer;
