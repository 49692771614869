import React from 'react';
import { Box } from '@rebass/grid';
import styled from 'styled-components';
import StyledTable from './StyledTable';
import PropTypes from 'prop-types';
import LinkButton from '../../common/LinkButton';
import NumberInput from '../../common/NumberInput';
import { withNamespaces } from 'react-i18next';

const NewIgnoreLineButton = styled(LinkButton)`
  font-size: 15px;
  margin-top: 10px;
`;

const DeleteIgnoredLineButton = styled(LinkButton)`
  color: #ff3b3b;
  font-size: 15px;
`;

class IgnoreLine extends React.Component {
  onChangePosition = (value) => {
    this.props.onChange.bind(null, { position: value })();
  };

  onChangeValue = (event) => {
    this.props.onChange.bind(null, { value: event.target.value })();
  };

  render() {
    return (
      <tr>
        <td>
          <Box>
            <NumberInput
              min={0}
              onChange={this.onChangePosition}
              value={this.props.position}
            />
          </Box>
        </td>
        <td>
          <Box>
            <input
              onChange={this.onChangeValue}
              type="text"
              value={this.props.value}
            />
          </Box>
        </td>
        <td>
          <DeleteIgnoredLineButton onClick={this.props.onDelete}>
            Eliminar
          </DeleteIgnoredLineButton>
        </td>
      </tr>
    );
  }
}

IgnoreLine.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  position: PropTypes.number,
  value: PropTypes.string,
};

function IgnoreLinesConfiguration(props) {
  const { t } = props;

  return (
    <div>
      <StyledTable>
        <thead>
          <tr>
            <th>{t('column_number')}</th>
            <th>{t('content')}</th>
            <th>{t('delete')}</th>
          </tr>
        </thead>
        <tbody>
          {props.value.map((line, i) => (
            <IgnoreLine
              idx={i}
              key={i}
              onChange={props.onChange.bind(null, i)}
              onDelete={props.onDeleteIgnoredLine.bind(null, i)}
              position={line.position}
              value={line.value}
            />
          ))}
        </tbody>
      </StyledTable>
      <NewIgnoreLineButton onClick={props.onAddIgnoredLine}>
        {t('new_line_to_ignore')}
      </NewIgnoreLineButton>
    </div>
  );
}

IgnoreLinesConfiguration.propTypes = {
  onAddIgnoredLine: PropTypes.func,
  onChange: PropTypes.func,
  onDeleteIgnoredLine: PropTypes.func,
  value: PropTypes.array,
};

export default withNamespaces()(IgnoreLinesConfiguration);
