const ERROR_MAP = {
  '1':
    'Se ha indicado la posición %{position} por fuera de la longitud de la fila.',
  '2':
    'El campo en la posición %{position} contiene el valor %{value} en la fila %{line}, y eso no se condicen con lo marcado.',
  '3':
    'La cantidad de líneas de headers y/o el delimitador no han sido completados.',
};

export function translateError(error) {
  let error_str = ERROR_MAP[error.code];
  error_str = error_str.replace('%{position}', error.position);
  error_str = error_str.replace('%{value}', error.value);
  error_str = error_str.replace('%{line}', error.line);
  return error_str;
}
