import conciliationService from './../services/conciliationService';
import _ from 'lodash';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(originalMoment);

const today = moment();

const INITIAL_STATE = {
  conciliations: [],
  pages: 1,
  page: 1,
  fetching: false,
  selectedItems: [],
  totalPending: 0,
  sorting: {
    column: 'date',
    direction: 'desc',
  },
  filters: {
    showAll: false,
    shop: null,
    card: null,
    dateRange: moment.range(today.clone().subtract(1, 'week'), today.clone()),
  },
};

function toCamelCase(operations) {
  return operations.map((operation) => {
    return {
      id: operation.id,
      cardType: operation['card_type'],
      description: operation.description,
      installment: operation.installment,
      shopName: operation['shop_name'],
      payDate: operation['pay_date'],
      batchNumber: operation['batch_number'],
      card: operation.card,
      plan: operation.plan,
      amountCents: operation['amount_cents'],
      voucher: operation.voucher,
      date: operation.date,
      presentationDate: operation['presentation_date'],
      clearingTableId: operation['clearing_table_id'],
      scanningNumber: operation['scanning_reconcilied'],
      value: {
        amount: operation.value.amount,
        isoCode: operation.value['iso_code'],
        symbol: operation.value.symbol,
      },
      totalAmount: {
        amount: operation['total_amount'].amount,
        isoCode: operation['total_amount']['iso_code'],
        symbol: operation['total_amount'].symbol,
      },
    };
  });
}

function formatFilters(filters) {
  const ret = {
    show_all: filters.showAll,
    daterange: `${filters.dateRange.start.format(
      'DD/MM/YYYY'
    )} - ${filters.dateRange.end.format('DD/MM/YYYY')}`,
  };
  if (filters.shop) {
    ret['shop_id'] = filters.shop;
  }
  if (filters.card) {
    ret['card_type'] = filters.card;
  }
  return ret;
}
export function fetchConciliations() {
  return (dispatch, getState) => {
    const { filters, page, sorting } = getState().conciliations;
    dispatch({ type: 'CONCILIATIONS_REQUEST' });
    return conciliationService
      .getTransactions(page, formatFilters(filters), sorting)
      .then((json) => {
        const conciliations_data = json.data;
        const total_pending =
          conciliations_data.total *
          (1 - conciliations_data.conciliated_percentage);
        dispatch({
          type: 'CONCILIATIONS_FETCHED',
          conciliations: toCamelCase(conciliations_data.operations),
          pages: conciliations_data.pages,
          totalPending: total_pending,
        });
      });
  };
}

export function filterConciliations(filters) {
  return (dispatch) => {
    dispatch({ type: 'CONCILIATIONS_SET_FILTERS', filters });
    return dispatch(fetchConciliations());
  };
}

export function paginateConciliations(page) {
  return (dispatch) => {
    dispatch({ type: 'CONCILIATIONS_SET_PAGE', page });
    return dispatch(fetchConciliations());
  };
}

export function selectConciliation(item, selected) {
  return (dispatch, getState) => {
    const { selectedItems } = getState().conciliations;
    if (selected) {
      return dispatch({
        type: 'CONCILIATIONS_SELECTION',
        selectedItems: _.uniq(selectedItems.concat(item)),
      });
    }
    return dispatch({
      type: 'CONCILIATIONS_SELECTION',
      selectedItems: selectedItems.filter((i) => i.id !== item.id),
    });
  };
}

export function conciliateSelected() {
  return (dispatch, getState) => {
    const { selectedItems, conciliations } = getState().conciliations;
    conciliationService.conciliate(selectedItems.map((i) => i.id));
    const newConciliations = conciliations.map((conciliation) => {
      if (selectedItems.find((i) => i.id === conciliation.id)) {
        conciliation.scanningNumber = 1;
      }
      return conciliation;
    });
    return dispatch({
      type: 'CONCILIATIONS_AFTER_CONCILIATE',
      conciliations: newConciliations,
    });
  };
}

export function deprecateSelected() {
  return (dispatch, getState) => {
    const { selectedItems, conciliations } = getState().conciliations;
    conciliationService.deprecate(selectedItems.map((i) => i.id));
    const newConciliations = conciliations.map((conciliation) => {
      if (selectedItems.find((i) => i.id === conciliation.id)) {
        conciliation.scanningNumber = 0;
      }
      return conciliation;
    });
    return dispatch({
      type: 'CONCILIATIONS_AFTER_CONCILIATE',
      conciliations: newConciliations,
    });
  };
}

export function sort(sorting) {
  return (dispatch) => {
    dispatch({ type: 'CONCILIATIONS_SORT', sorting });
    return dispatch(fetchConciliations());
  };
}

const conciliationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CONCILIATIONS_REQUEST':
      return { ...state, fetching: true };
    case 'CONCILIATIONS_FETCHED':
      return {
        ...state,
        conciliations: action.conciliations,
        pages: action.pages,
        totalPending: action.totalPending,
        fetching: false,
      };
    case 'CONCILIATIONS_SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.filters } };
    case 'CONCILIATIONS_SORT':
      return { ...state, sorting: action.sorting };
    case 'CONCILIATIONS_SET_PAGE':
      return { ...state, page: action.page };
    case 'CONCILIATIONS_SELECTION':
      return { ...state, selectedItems: action.selectedItems };
    case 'CONCILIATIONS_AFTER_CONCILIATE':
      return {
        ...state,
        selectedItems: [],
        conciliations: action.conciliations,
      };
    default:
      return state;
  }
};

export default conciliationsReducer;
