import React from 'react';
import StyledTable from './StyledTable';
import ConfigurationRow from './ConfigurationRow';
import PropTypes from 'prop-types';
import _ from 'lodash';

class FieldsConfiguration extends React.Component {
  getRowValue(value) {
    return _.find(this.props.value, { fieldBLocation: value });
  }

  render() {
    const { locked } = this.props;
    return (
      <StyledTable>
        <thead>
          <tr>
            <th className="field-name-col">Nombre de campo</th>
            <th className="col-number-col">Número de columna</th>
            <th className="reg-type-col">Tipo de registro</th>
            {!locked && (
              <React.Fragment>
                <th className="reg-type-col">Agregar al reporte</th>
                <th />
              </React.Fragment>
            )}
          </tr>
        </thead>

        <tbody>
          {this.props.fieldNames.map((field, i) => {
            const rowVal = this.getRowValue(field.value);
            return (
              <ConfigurationRow
                dataTypes={this.props.dataTypes}
                dateFormats={this.props.dateFormats}
                field={field}
                increasecardProviders={this.props.increasecardProviders}
                informativeFields={this.props.informativeFields}
                key={i}
                locked={this.props.locked}
                onChange={this.props.onChange.bind(null, field.value)}
                onInformativeChange={this.props.onInformativeChange.bind(
                  null,
                  field.value
                )}
                onToggle={this.props.onToggleVal.bind(null, field.value)}
                value={rowVal}
              />
            );
          })}
        </tbody>
      </StyledTable>
    );
  }
}

FieldsConfiguration.propTypes = {
  dataTypes: PropTypes.array,
  dateFormats: PropTypes.array,
  fieldNames: PropTypes.array,
  increasecardProviders: PropTypes.array,
  informativeFields: PropTypes.bool,
  locked: PropTypes.bool,
  onChange: PropTypes.func,
  onInformativeChange: PropTypes.func,
  onToggleVal: PropTypes.func,
  value: PropTypes.array,
};

export default FieldsConfiguration;
