import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';

const StyledFlex = styled(Flex)`
  height: 100%;

  label {
    color: #243568;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    input {
      margin: 0 10px 0 27px;
    }
  }
`;

const ConciliationTypeSelector = ({
  t,
  value,
  onChange,
  allowEmpty,
  readOnly,
}) => {
  const selectionChange = (e) => {
    if (!readOnly && e.target.checked) {
      onChange(e.target.value);
    }
  };

  return (
    <StyledFlex alignItems={'center'}>
      {(!readOnly || value === 'movements') && (
        <label>
          <input
            checked={value === 'movements'}
            onChange={selectionChange}
            type="radio"
            value={'movements'}
          />
          {t('general.movements')}
        </label>
      )}
      {(!readOnly || value === 'payments') && (
        <label>
          <input
            checked={value === 'payments'}
            onChange={selectionChange}
            type="radio"
            value={'payments'}
          />
          {t('general.payments')}
        </label>
      )}
      {!readOnly && allowEmpty && (
        <label>
          <input
            checked={value === ''}
            onChange={selectionChange}
            type="radio"
            value={''}
          />
          {t('general.both')}
        </label>
      )}
    </StyledFlex>
  );
};

ConciliationTypeSelector.propTypes = {
  allowEmpty: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOf(['movements', 'payments', '']),
};

ConciliationTypeSelector.defaultProps = {
  allowEmpty: true,
  readOnly: false,
};

export default withNamespaces()(ConciliationTypeSelector);
