import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../common/Table';
import styled from 'styled-components';
import classnames from 'classnames';
import { CheckSquare, Square } from 'styled-icons/fa-regular';
import { formatCurrency } from '../../../helpers';
import FilterTableHead from '../../common/FilterTableHead';
import TableHead from '../../common/TableHead';
import { withNamespaces } from 'react-i18next';

const StyledTable = styled(Table)`
  border: 1px solid #ededed;
  border-spacing: 0;

  tr {
    svg {
      width: 15px;
      height: 15px;
    }
    td {
      font-weight: normal;
      background-color: white;
      border-top: 2px solid #ededed;
    }
  }
  tr.conciliated * {
    color: rgba(0, 221, 0, 0.2);
  }

  & tr:nth-child(even) td {
    background-color: white;
  }

  tr.selected {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16);
    transform: scale(1.01);
    td {
      background-color: #f2f6f7;
    }
  }
`;

class PaymentsTableRow extends React.Component {
  onClick() {
    if (
      this.props.payment.scanningReconcilied === null &&
      !this.props.disabled
    ) {
      this.props.onSelectChange(!this.props.selected);
    }
  }

  render() {
    const { payment, selected, disabled } = this.props;
    const trClassNames = classnames({
      selected: selected,
      conciliated:
        payment.scanningReconcilied > 0 || payment.scanningReconcilied === -1,
      deprecated: payment.scanningReconcilied === 0,
    });

    return (
      <tr className={trClassNames} onClick={this.onClick.bind(this)}>
        <td>
          {!disabled &&
            (selected || payment.scanningReconcilied > 0 ? (
              <CheckSquare />
            ) : (
              <Square />
            ))}
        </td>
        <td>{payment.provider}</td>
        <td>{payment.paymentDate}</td>
        <td>
          {formatCurrency(
            payment.totalEarn.amount / 100,
            payment.totalEarn.currency
          )}
        </td>
        <td>{payment.storeNumber}</td>
        <td>{payment.storeName}</td>
        <td>{payment.clearingNumber}</td>
      </tr>
    );
  }
}

PaymentsTableRow.propTypes = {
  disabled: PropTypes.bool,
  onSelectChange: PropTypes.func,
  payment: PropTypes.object,
  selected: PropTypes.bool,
};

function PaymentsTable({
  t,
  loading,
  data,
  disabled,
  onSelectChange,
  selectedItems,
  onFilter,
  filters,
}) {
  return (
    <StyledTable loading={loading}>
      <thead>
        <tr>
          <th />
          <FilterTableHead
            filterValue={filters.provider}
            onFilter={(value) => onFilter('provider', value)}
          >
            {t('payments.table.provider')}
          </FilterTableHead>
          <TableHead>{t('payments.table.payment_date')}</TableHead>
          <TableHead>{t('payments.table.total_earn')}</TableHead>
          <TableHead>{t('payments.table.stablishment_number')}</TableHead>
          <TableHead>{t('payments.table.store_name')}</TableHead>
          <TableHead>{t('payments.table.clearing_number')}</TableHead>
        </tr>
      </thead>

      <tbody>
        {data.map((row, i) => (
          <PaymentsTableRow
            disabled={disabled}
            key={i}
            onSelectChange={onSelectChange.bind(this, row)}
            payment={row}
            selected={!!selectedItems.find((i) => i.id === row.id)}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}

PaymentsTable.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  filters: PropTypes.object,
  loading: PropTypes.bool,
  onFilter: PropTypes.func,
  onSelectChange: PropTypes.func,
  selectedItems: PropTypes.array,
};

export default withNamespaces()(PaymentsTable);
