import { getIncreaseData } from '../services/increaseDataService';

const INITIAL_STATE = {
  cards: [],
  shops: [],
  fetching: false,
};

function toCamelCase(increaseData) {
  return {
    cards: increaseData['increase_cards'],
    shops: increaseData['increase_shops'],
  };
}

export function fetchIncreaseData() {
  return (dispatch) => {
    dispatch({ type: 'INCREASE_DATA_REQUEST' });
    return getIncreaseData().then((json) =>
      dispatch({ type: 'INCREASE_DATA_FETCHED', ...toCamelCase(json.data) })
    );
  };
}

const increaseDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INCREASE_DATA_REQUEST':
      return { ...state, cards: [], shops: [], fetching: true };
    case 'INCREASE_DATA_FETCHED':
      return {
        ...state,
        cards: action.cards,
        shops: action.shops,
        fetching: false,
      };
    default:
      return state;
  }
};

export default increaseDataReducer;
