import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInput = styled.input`
  font-size: 15px;
  border-radius: 3px;
  border: 1px solid #ededed;
  padding: 10px 3px 7px 10px;
  color: #243568;

  :focus {
    border-color: #243568;
  }
`;

class TextInput extends React.Component {
  handleChange(event) {
    const value = event.target.value;
    this.props.onChange(value);
  }

  render() {
    return (
      <div>
        <StyledInput
          onChange={this.handleChange.bind(this)}
          placeholder={this.props.placeholder}
          type="text"
          value={this.props.value}
        />
      </div>
    );
  }
}

TextInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default TextInput;
