import React, { Component } from 'react';
import { Box, Flex } from '@rebass/grid';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { PopupboxManager } from 'react-popupbox';

import Paginator from '../../common/Paginator';
import DetailPopupProcessing from './DetailPopupProcessing';
import DetailPopup from './DetailPopup';
import { fetchReports } from '../../../reducers/reportsReducer';
import {
  getReportLink,
  notifyReportDownload,
} from '../../../services/reportsService';
import ReportsTable from './ReportsTable';
import { withNamespaces } from 'react-i18next';
import ConciliationTypeSelector from '../../common/ConciliationTypeSelector';
import TextInput from '../../common/TextInput';

class Reports extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchReports());
    this.timer = setInterval(() => dispatch(fetchReports()), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  downloadFile(link) {
    const a = document.createElement('a');
    a.href = link;
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onDownloadClicked(id) {
    getReportLink(id)
      .then((link) => this.downloadFile(link))
      .then(notifyReportDownload(id));
  }

  changeFilter(filter, value) {
    this.props.onFilterChange({ ...this.props.filters, [filter]: value });
  }

  onDetailClicked(report) {
    if (report.status === 0) {
      PopupboxManager.open({ content: <DetailPopupProcessing /> });
    } else {
      PopupboxManager.open({
        content: (
          <DetailPopup
            onDownloadClicked={this.onDownloadClicked.bind(this, report.id)}
            report={report}
          />
        ),
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Flex>
          <Box>
            <h1>{t('my_reports')}</h1>
          </Box>
        </Flex>
        <Flex>
          <Box>
            <TextInput
              onChange={(value) => this.changeFilter('name', value)}
              placeholder={t('search_report')}
              value={this.props.filters.name}
            />
          </Box>
          <Box>
            <ConciliationTypeSelector
              onChange={(value) => this.changeFilter('type', value)}
              value={this.props.filters.type}
            />
          </Box>
        </Flex>

        <Flex mt={20}>
          <ReportsTable
            data={this.props.reports}
            loading={this.props.fetching}
            onDetailClicked={this.onDetailClicked.bind(this)}
            onDownloadClicked={this.onDownloadClicked.bind(this)}
          />
        </Flex>
        <Flex justifyContent="flex-end" my="17px">
          <Paginator
            onPageSelect={this.props.onPageSelect}
            pages={this.props.pages}
            selected={this.props.currentPage}
          />
        </Flex>
      </React.Fragment>
    );
  }
}

Reports.propTypes = {
  currentPage: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.oneOf(['movements', 'payments', '']),
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onPageSelect: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  reports: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onFilterChange: (filters) => {
    dispatch({ type: 'SET_FILTERS', filters });
    return dispatch(fetchReports());
  },
  onPageSelect: (page) => {
    dispatch({ type: 'SET_PAGE', page });
    return dispatch(fetchReports());
  },
});

function mapStateToProps(state) {
  const reportsState = state.reports;

  return {
    reports: reportsState.reports,
    currentPage: reportsState.currentPage,
    pages: reportsState.pages,
    filters: reportsState.filters,
    fetching: reportsState.fetching,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Reports));
