import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import connect from 'react-redux/es/connect/connect';
import Dropzone from 'react-dropzone';
import { Flex, Box } from '@rebass/grid';

import ProfileCard from './ProfileCard';

import { uploadFile } from '../../../reducers/newConciliationReducer';
import {
  destroyProfile,
  fetchProfiles,
} from '../../../reducers/profilesReducer';
import ProfileCardContainer from './ProfileCardContainer';
import LinkButton from '../../common/LinkButton';
import FullPageSpinner from '../../common/FullPageSpinner';
import { fetchStructureMetadata } from '../../../reducers/fileStructureReducer';
import { withNamespaces } from 'react-i18next';
import ConciliationTypeSelector from '../../common/ConciliationTypeSelector';

const NewProfileCardContainer = styled(ProfileCardContainer)`
  border: 1px solid ${(props) => (props.disabled ? '#A3A3A3' : '#3b86ff')};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 239px);
  grid-auto-rows: 216px;
  grid-column-gap: 67px;
  grid-row-gap: 67px;
`;

const FilterBar = styled(Box)`
  border-top: 1px solid #bcc1d0;
  border-bottom: 1px solid #bcc1d0;
  padding: 18px 0;
  margin-bottom: 25px;

  span {
    color: #243568;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
  }
`;

const propTypes = {
  credentials: PropTypes.object,
  dispatch: PropTypes.func,
  fetching: PropTypes.bool,
  history: PropTypes.any,
  masquerading: PropTypes.bool,
  profiles: PropTypes.array,
  s3Key: PropTypes.string,
};

class Profiles extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchProfiles());
    dispatch(fetchStructureMetadata());
  }

  handleNewProfile() {
    this.props.history.push('/new_profile');
  }

  handleProfileDetail(profileId) {
    this.props.history.push(`/edit_profile/${profileId}`);
  }

  handleProfileDestroy(profileId) {
    this.props.dispatch(destroyProfile(profileId));
  }

  handleProfileClone(profile, file) {
    this.props.dispatch(uploadFile(file)).then(() => {
      profile.id = null;
      profile.originalStable = false;
      profile.stable = false;
      this.props.dispatch({ type: 'SET_PROFILE', profile });
      this.props.history.push('/new_profile');
    });
  }

  totalScannings(profile) {
    return profile.scannings.length;
  }

  isDropZone() {
    return !this.props.s3Key;
  }

  handleDrop(event) {
    const { dispatch, history } = this.props;
    const file = event[0];
    dispatch(uploadFile(file)).then(() => {
      history.push('/new_profile');
    });
  }

  render() {
    const {
      t,
      profiles,
      masquerading,
      credentials,
      fetching,
      filters,
      onFilterChange,
    } = this.props;
    if (fetching) {
      return <FullPageSpinner />;
    }

    const addProfileButton = this.isDropZone() ? (
      <LinkButton onClick={() => this.handleNewProfile()}>
        <Dropzone
          disableClick={false}
          multiple={false}
          onDrop={(event) => this.handleDrop(event)}
          style={{}}
        >
          {t('add_new_profile')}
        </Dropzone>
      </LinkButton>
    ) : (
      <LinkButton onClick={() => this.handleNewProfile()}>
        {t('add_new_profile')}
      </LinkButton>
    );

    return (
      <Flex flexDirection={'column'} mb={40}>
        <FilterBar>
          <Flex>
            <span>{t('profiles.show_profiles_for')}</span>
            <ConciliationTypeSelector
              onChange={(type) => onFilterChange({ type })}
              value={filters.type}
            />
          </Flex>
        </FilterBar>
        <Grid>
          {profiles.map((profile, index) => (
            <ProfileCard
              conciliationsCount={profile.conciliationsCount}
              key={index}
              lastConciliationDate={profile.lastConciliationDate}
              masquerading={masquerading}
              name={profile.name}
              onClone={(file) => this.handleProfileClone(profile, file)}
              onDestroy={() => this.handleProfileDestroy(profile.id)}
              onDetail={() => this.handleProfileDetail(profile.id)}
              totalScannings={this.totalScannings(profile)}
            />
          ))}
          {masquerading && (
            <NewProfileCardContainer disabled={!credentials} key={'new'}>
              {addProfileButton}
            </NewProfileCardContainer>
          )}
        </Grid>
      </Flex>
    );
  }
}

Profiles.propTypes = propTypes;

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onFilterChange: (filters) => {
    dispatch({ type: 'PROFILES_SET_FILTERS', filters });
    return dispatch(fetchProfiles());
  },
});

function mapStateToProps(fullState) {
  const state = fullState.profiles;
  const newConciliationState = fullState.newConciliation;
  const masqueradingToken = fullState.auth.credentials['X-Masqueraded'];
  const masquerading =
    masqueradingToken !== undefined &&
    masqueradingToken !== null &&
    masqueradingToken !== '';

  return {
    credentials: fullState.auth.credentials,
    fetching: state.fetching,
    masquerading,
    profiles: state.profiles,
    filters: state.filters,
    s3Key: newConciliationState.s3Key,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Profiles));
