import React from 'react';
import styled from 'styled-components';
import Card from '../../common/Card';
import { Box, Flex } from '@rebass/grid';
import { Close } from 'styled-icons/material';
import { PopupboxManager } from 'react-popupbox';
import PropTypes from 'prop-types';
import Select from '../../common/Select';
import LinkButton from '../../common/LinkButton';
import Button from '../../common/Button';

const StyledCard = styled(Card)`
  width: 700px;
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  text-align: left;
  position: relative;
  > div {
    margin-bottom: 30px;
  }
  .title-container {
    border-bottom: 1px solid #bcc1d0;
  }
  h1 {
    color: #243568;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 20px;
    margin: 12px 0;
  }
  svg.close-button {
    cursor: pointer;
    color: #243568;
    width: 16px;
    height: 16px;
  }
  .card-select {
    width: 100%;
  }
  p {
    font-size: 10px;
  }
  textarea {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ededed;
    border-radius: 3px;
    resize: none;
    color: #243568;
    padding: 10px 12px;
    font-size: 15px;
  }
  .apply-button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44px;
    font-size: 14px;
    border-radius: 0;
    box-shadow: none;
    border-radius: 0 0 6px 6px;
  }
`;

const DeleteCardButton = styled(LinkButton)`
  color: #ff3b3b;
`;

class CardConfiguration extends React.Component {
  handleCardChange(value) {
    this.props.onChange({ ...this.props.value, originalValue: value });
  }

  handleAliasChange(event) {
    this.props.onChange({
      ...this.props.value,
      possibleValues: event.currentTarget.value,
    });
  }

  render() {
    const { disabled, increaseCardProviders, value, onDelete } = this.props;
    return (
      <div>
        <Flex>
          <Box width="170px">
            <Flex flexDirection="column">
              <Box width="100%">
                <Select
                  className="card-select"
                  disabled={disabled}
                  onSelect={(value) => this.handleCardChange(value)}
                  options={increaseCardProviders}
                  placeholder="Seleccionar"
                  selected={value.originalValue}
                />
              </Box>
              {!disabled && (
                <Box>
                  <p>
                    Agregá todos los nombres o “alias” que uses para esta
                    tarjeta separados con una coma.
                  </p>
                </Box>
              )}
            </Flex>
          </Box>
          <Box flex={1} pl="10px">
            <textarea
              disabled={disabled}
              onChange={(event) => this.handleAliasChange(event)}
              value={value.possibleValues}
            />
          </Box>
        </Flex>
        {!disabled && (
          <Flex justifyContent="flex-end">
            <Box width="fit-content">
              <DeleteCardButton onClick={onDelete}>Eliminar</DeleteCardButton>
            </Box>
          </Flex>
        )}
      </div>
    );
  }
}

CardConfiguration.propTypes = {
  disabled: PropTypes.bool,
  increaseCardProviders: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.object,
};

class AdvancedCardConfigurationPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  handleCardChange(i, value) {
    this.setState((prevState) => {
      const values = [...prevState.value];
      values[i] = value;
      return { value: values };
    });
  }

  handleAddCard() {
    this.setState((prevState) => ({
      value: [...prevState.value, { originalValue: null, possibleValues: '' }],
    }));
  }

  handleRemoveCard(i) {
    this.setState((prevState) => {
      const val = [...prevState.value];
      val.splice(i, 1);
      return { value: val };
    });
  }

  handleSave() {
    this.props.onSave(this.state.value);
    PopupboxManager.close();
  }

  render() {
    const { onlyRead, increaseCardProviders } = this.props;
    return (
      <StyledCard>
        <Flex alignItems="center" className="title-container">
          <Box flex="1">
            <h1>Configuración avanzada</h1>
          </Box>
          <Box>
            <Close className="close-button" onClick={PopupboxManager.close} />
          </Box>
        </Flex>
        {this.state.value.map((value, i) => (
          <CardConfiguration
            disabled={onlyRead}
            increaseCardProviders={increaseCardProviders}
            key={i}
            onChange={(value) => this.handleCardChange(i, value)}
            onDelete={() => this.handleRemoveCard(i)}
            value={value}
          />
        ))}
        {!onlyRead && (
          <div>
            <LinkButton onClick={() => this.handleAddCard()}>
              Agregar Alias de otra Tarjeta
            </LinkButton>
            <Button className="apply-button" onClick={() => this.handleSave()}>
              APLICAR
            </Button>
          </div>
        )}
      </StyledCard>
    );
  }
}

AdvancedCardConfigurationPopup.propTypes = {
  increaseCardProviders: PropTypes.array,
  onSave: PropTypes.func,
  onlyRead: PropTypes.bool,
  value: PropTypes.array,
};

export default AdvancedCardConfigurationPopup;
