import paymentsService from './../services/paymentsService';
import _ from 'lodash';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import camelcaseKeys from 'camelcase-keys';

const moment = extendMoment(originalMoment);

const today = moment();

const INITIAL_STATE = {
  payments: [],
  pages: 1,
  page: 1,
  fetching: false,
  selectedItems: [],
  pendingAmount: 0,
  sorting: {
    //column: 'date',
    //direction: 'desc',
  },
  filters: {
    showAll: false,
    provider: '',
    //shop: "",
    range: { min: null, max: null },
    dateRange: moment.range(today.clone().subtract(1, 'week'), today.clone()),
  },
};

function formatFilters(filters) {
  const ret = {
    show_all: filters.showAll,
    daterange: `${filters.dateRange.start.format(
      'DD/MM/YYYY'
    )} - ${filters.dateRange.end.format('DD/MM/YYYY')}`,
  };
  if (filters.range.min !== undefined) {
    ret['from_total_earn'] = filters.range.min * 100;
  }
  if (filters.range.max !== undefined && filters.range.max !== null) {
    ret['to_total_earn'] = filters.range.max * 100;
  }
  if (filters.stablishment) {
    ret['stablishment_number'] = filters.stablishment;
  }
  if (filters.card) {
    ret['card_type'] = filters.card;
  }
  if (filters.provider) {
    ret['provider'] = filters.provider;
  }

  return ret;
}

export function fetchPayments() {
  return (dispatch, getState) => {
    dispatch({ type: 'PAYMENTS_REQUEST' });
    const { filters, page, sorting } = getState().payments;

    return paymentsService
      .getPayments(page, formatFilters(filters), sorting)
      .then((json) => {
        const paymentsData = camelcaseKeys(json.data, { deep: true });
        dispatch({
          type: 'PAYMENTS_FETCHED',
          payments: paymentsData.clearings,
          pages: paymentsData.pages,
          pendingAmount: paymentsData.pendingAmount,
        });
      });
  };
}

export function filterPayments(filters) {
  return (dispatch) => {
    dispatch({ type: 'PAYMENTS_SET_FILTERS', filters });
    return dispatch(fetchPayments());
  };
}

export function selectPayment(item, selected) {
  return (dispatch, getState) => {
    const { selectedItems } = getState().payments;
    if (selected) {
      return dispatch({
        type: 'PAYMENTS_SELECTION',
        selectedItems: _.uniq(selectedItems.concat(item)),
      });
    }
    return dispatch({
      type: 'PAYMENTS_SELECTION',
      selectedItems: selectedItems.filter((i) => i.id !== item.id),
    });
  };
}

export function paymentSelected() {
  return (dispatch, getState) => {
    const { selectedItems, payments } = getState().payments;
    paymentsService.conciliate(selectedItems.map((i) => i.id));
    const newPayments = payments.map((payment) => {
      if (selectedItems.find((i) => i.id === payment.id)) {
        payment.clearingNumber = 1;
      }
      return payment;
    });

    return dispatch({
      type: 'PAYMENTS_AFTER_CONCILIATION',
      payments: newPayments,
    });
  };
}

export function sort(sorting) {
  return (dispatch) => {
    dispatch({ type: 'PAYMENTS_SORT', sorting });
    return dispatch(fetchPayments());
  };
}

const paymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'PAYMENTS_REQUEST':
      return { ...state, fetching: true };
    case 'PAYMENTS_FETCHED':
      return {
        ...state,
        payments: action.payments,
        pages: action.pages,
        pendingAmount: action.pendingAmount,
        fetching: false,
      };
    case 'PAYMENTS_SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.filters } };
    case 'PAYMENTS_SORT':
      return { ...state, sorting: action.sorting };
    case 'PAYMENTS_SET_PAGE':
      return { ...state, page: action.page };
    case 'PAYMENTS_SELECTION':
      return { ...state, selectedItems: action.selectedItems };
    case 'PAYMENTS_AFTER_CONCILIATION':
      return {
        ...state,
        selectedItems: [],
        payments: action.payments,
      };
    default:
      return state;
  }
};

export default paymentsReducer;
