import styled from 'styled-components';
import { Spinner as SpinnerIcon } from '../../../node_modules/styled-icons/fa-solid/Spinner.cjs';

const Spinner = styled(SpinnerIcon)`
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  color: #707070;
  width: 22px;
  height: 22px;
  animation: spinner 1s linear infinite;
`;

export default Spinner;
