import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { withNamespaces } from 'react-i18next';

const StyledFooter = styled(Flex)`
  border-top: 1px solid #ededed;
  padding-top: 15px;
  margin: 0 100px 15px;
  margin-bottom: 15px;
  width: calc(100% - 200px);
`;

const Copy = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: #999999;
  white-space: nowrap;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

const FooterLink = styled.a`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
  text-decoration: none;
  color: #999999;
`;

function Footer({ t }) {
  return (
    <StyledFooter>
      <Copy>&copy; Increase</Copy>
      <FooterLinks>
        <FooterLink
          href="https://soporte.increasecard.com/hc/es"
          target="blank"
        >
          {t('help_center')}
        </FooterLink>
        <FooterLink
          href="https://increasecard.com/terminos-y-condiciones/"
          target="blank"
        >
          {t('terms_and_conditions')}
        </FooterLink>
        <FooterLink href="https://increasecard.com/privacidad/" target="blank">
          {t('privacy_policies')}
        </FooterLink>
      </FooterLinks>
    </StyledFooter>
  );
}

export default withNamespaces()(Footer);
