import React from 'react';
import FileUpload from '../../common/FileUpload/FileUpload';
import { Box, Flex } from '@rebass/grid';
import ProfileSelection from './ProfileSelection';
import Button from '../../common/Button';
import connect from 'react-redux/es/connect/connect';
import { withNamespaces } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { renameKeys } from './../../../helpers';
import {
  fetchProfiles,
  notifyUpload,
  uploadFile,
} from './../../../reducers/newConciliationReducer';

class NewConciliation extends React.Component {
  componentDidMount() {
    this.props.fetchProfiles();
  }

  isProfileSelectionDisabled() {
    return !this.props.s3Key;
  }

  isConciliationDisabled() {
    return (
      this.isProfileSelectionDisabled() ||
      !this.props.profileId ||
      !(this.props.fileUploadStatus === FileUpload.COMPLETED)
    );
  }

  onNewProfile = () => this.props.history.push('/new_profile');

  render() {
    const {
      t,
      fileUploadStatus,
      fileUploadProgress,
      onDropAccepted,
      onDropRejected,
      onRetry,
      onProfileSelection,
      onConciliation,
      profileId,
      profiles,
      masquerading,
      lockedSimulate,
      simulate,
      onCheckBox,
    } = this.props;

    return (
      <Flex alignItems="center" flexDirection="column">
        <h1>{t('new_conciliation')}</h1>
        <Box width="25rem">
          <FileUpload
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            onRetry={onRetry}
            progress={fileUploadProgress}
            status={fileUploadStatus}
          />
        </Box>
        <Box mt={20} width="25rem">
          <ProfileSelection
            disabled={this.isProfileSelectionDisabled()}
            masquerading={masquerading}
            onChange={onProfileSelection}
            onNewProfile={this.onNewProfile}
            profiles={profiles}
            selectedProfile={profileId}
          />
        </Box>
        <ReactTooltip effect="solid" place="bottom" type="info" />
        <Box mb={100} mt={20}>
          <p data-tip={t('simulated_tooltip')}>
            <input
              checked={simulate}
              disabled={lockedSimulate}
              onChange={onCheckBox}
              type="checkbox"
            />
            {t('perform_simulated_conciliation')}
          </p>
        </Box>
        <Box mt={20}>
          <Button
            disabled={this.isConciliationDisabled()}
            onClick={onConciliation}
            size="large"
          >
            {t('conciliate')}
          </Button>
        </Box>
      </Flex>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProfiles: () => {
    return dispatch(fetchProfiles());
  },
  onRetry: () => dispatch({ type: 'RETRY_UPLOAD' }),
  onDropAccepted: (file) => {
    dispatch(
      uploadFile(file, () => {
        dispatch({ type: 'UPLOAD_ERROR' });
      })
    );
  },
  onDropRejected: () => {
    dispatch({ type: 'FILE_TYPE_ERROR' });
  },
  onProfileSelection: (profileId) => {
    dispatch({ type: 'SET_PROFILE', profileId });
  },
  onCheckBox: (event) => {
    dispatch({ type: 'SET_SIMULATE', simulate: event.target.checked });
  },
  onConciliation: () => {
    dispatch(notifyUpload()).then(() => {
      ownProps.history.push('/reports');
    });
  },
});

function mapStateToProps(fullState) {
  const state = fullState.newConciliation;
  const masqueradingToken = fullState.auth.credentials['X-Masqueraded'];
  const masquerading =
    masqueradingToken !== undefined &&
    masqueradingToken !== null &&
    masqueradingToken !== '';
  return {
    fileUploadStatus: state.fileUploadStatus,
    fileUploadProgress: state.fileUploadProgress,
    file: state.file,
    s3Key: state.s3Key,
    profileId: state.profileId,
    profiles: state.profiles.map((profile) => {
      return renameKeys(profile, { id: 'value' });
    }),
    credentials: fullState.auth.credentials,
    masquerading,
    simulate: state.simulate,
    lockedSimulate: state.lockedSimulate,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewConciliation));
