import React from 'react';

import { Route } from 'react-router-dom';
import CheckV2AndLogin from './components/screens/CheckV2AndLogin/CheckV2AndLogin';
import NewConciliation from './components/screens/newConciliation/NewConciliation';
import Reports from './components/screens/reports/Reports';
import Conciliations from './components/screens/conciliations/Conciliations';
import Profiles from './components/screens/profiles/Profiles';
import NewProfile from './components/screens/newProfile/NewProfile';
import EditProfile from './components/screens/newProfile/EditProfile';
import Login from './components/screens/Login/Login';
import Payments from './components/screens/payments/Payments';
import V2 from './components/screens/v2/V2';

const routes = (
  <React.Fragment>
    <PrivateRoute component={NewConciliation} exact path="/" />
    <PrivateRoute component={Reports} path="/reports" />
    <PrivateRoute component={Conciliations} path="/conciliations" />
    <PrivateRoute component={Payments} path="/payments" />
    <PrivateRoute component={Profiles} path="/profiles" />
    <PrivateRoute component={NewProfile} path="/new_profile" />
    <PrivateRoute component={EditProfile} path="/edit_profile/:profileId" />
    <Route component={Login} path="/login" />
    <Route component={V2} path="/v2" />
  </React.Fragment>
);

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      component={(props) => (
        <CheckV2AndLogin {...props} component={Component} />
      )}
    />
  );
}
export default routes;
