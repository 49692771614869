import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Flex } from '@rebass/grid';
import classnames from 'classnames';
import _ from 'lodash';

const Container = styled(Flex)`
  .separators-container {
    margin-left: 6px;
    > div {
      padding: 13px 20px;
      font-family: Open Sans, sans-serif;
      color: black;
      font-size: 13px;
      border: 1px solid #ededed;
      background-color: white;
      border-right: none;
      cursor: pointer;
      input {
        font-size: 13px;
        width: 46px;
        height: 33px;
        border: 1px solid #ededed;
        border-radius: 3px;
        margin: -10px 0;
        padding-left: 7px;
      }
    }
    > div.selected {
      background-color: #f2f6f7;
      border: 1px solid #ededed;
      color: #3b86ff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.17);
    }
    > div:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    > div:last-child {
      border-right: 1px solid #ededed;
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

class SeparatorSelector extends React.Component {
  getOtherSeparatorValue() {
    if (
      this.props.selected === null ||
      _.some(this.props.commonSeparators, { value: this.props.selected })
    ) {
      return '';
    }
    return this.props.selected;
  }

  otherSeparatorChange(e) {
    this.props.onChange(e.nativeEvent.data);
  }

  render() {
    const otherSeparatorValue = this.getOtherSeparatorValue();
    return (
      <Container alignItems="center">
        <Box>
          <Flex className="separators-container">
            {this.props.commonSeparators.map((separator, i) => (
              <Box
                className={classnames({
                  selected: separator.value === this.props.selected,
                })}
                key={i}
                onClick={this.props.onChange.bind(null, separator.value)}
              >
                {separator.name}
              </Box>
            ))}
            <Box
              className={classnames({ selected: otherSeparatorValue !== '' })}
            >
              Otro:{' '}
              <input
                disabled={this.props.disabled}
                onChange={this.otherSeparatorChange.bind(this)}
                type="text"
                value={otherSeparatorValue}
              />
            </Box>
          </Flex>
        </Box>
      </Container>
    );
  }
}

SeparatorSelector.propTypes = {
  commonSeparators: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selected: PropTypes.string,
};

export default SeparatorSelector;
