import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'styled-icons/fa-solid';
import { Box, Flex } from '@rebass/grid';
import onClickOutside from 'react-onclickoutside';
import LinkButton from './LinkButton';

const StyledDiv = styled.div`
  position: relative; /* Enable absolute positioning for children and pseudo elements */
  padding: 6.5px 10px;
  background: #fff;
  border: 1px solid #ededed;
  cursor: pointer;
  border-radius: 3px;
  outline: none;
  font-size: 15px;
  font-family: Open Sans, sans-serif;
  text-align: left;
  color: #43425d;
  box-sizing: border-box;

  & ul {
    position: absolute;
    top: 90%;
    left: -1px;
    right: -1px;
    background: white;
    list-style: none;
    margin: 0;
    border-right: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    border-left: 1px solid #ededed;
    border-radius: 0 0 3px 3px;
    padding: 0;
    z-index: 2;
    max-height: 300px;
    overflow-y: auto;
    li {
      padding: 0;
      color: #3b86ff;
      button {
        padding: 10px;
        width: 100%;
        text-align: left;
        :hover {
          color: #3b86ff;
        }
      }
    }
  }
`;

const SelectOption = styled(LinkButton)`
  color: #43425d;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
`;

const SelectedContainer = styled(Box)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const DownArrow = styled(ChevronDown)`
  width: 11px;
  height: 11px;
  color: #43425d;
`;

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  onClick() {
    if (this.props.disabled) {
      return;
    }
    this.setState((prevState) => ({ active: !prevState.active }));
  }

  handleClickOutside() {
    this.setState({ active: false });
  }

  getSelected() {
    const sel = this.props.options.find(
      (op) => op.value === this.props.selected
    );
    return sel ? sel.name : this.props.placeholder;
  }

  onSelect(value) {
    this.props.onSelect(value);
  }

  render() {
    let dropdown = null;
    let extraButton = null;

    if (this.props.extraButtonText) {
      extraButton = (
        <li>
          <LinkButton onClick={this.props.onExtraButtonClick.bind(this)}>
            {this.props.extraButtonText}
          </LinkButton>
        </li>
      );
    }

    if (this.state.active) {
      dropdown = (
        <ul className="dropdown">
          {this.props.options.map((option, i) => (
            <li key={i}>
              <SelectOption onClick={this.onSelect.bind(this, option.value)}>
                {option.name}
              </SelectOption>
            </li>
          ))}
          {extraButton}
        </ul>
      );
    }

    return (
      <StyledDiv
        className={this.props.className}
        onClick={this.onClick.bind(this)}
      >
        <Flex>
          <SelectedContainer flex="1">
            <span>{this.getSelected()}</span>
          </SelectedContainer>
          <Box flex="0">
            <DownArrow />
          </Box>
        </Flex>
        {dropdown}
      </StyledDiv>
    );
  }
}

Select.propTypes = {
  disabled: PropTypes.bool,
  extraButtonText: PropTypes.string,
  onExtraButtonClick: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      name: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  selected: PropTypes.any,
};

Select.defaultProps = {
  placeholder: '',
};

export default onClickOutside(Select);
