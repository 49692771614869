import React from 'react';
import styled from 'styled-components';
import Card from '../../common/Card';
import Spinner from '../../common/Spinner';
import LinkButton from '../../common/LinkButton';
import { PopupboxManager } from 'react-popupbox';

const StyledCard = styled(Card)`
  width: 450px;
  background-color: #f7f7f7;
  border-radius: 6px;
  border: none;

  & h1 {
    margin-top: 0;
    font-family: Lato, sans-serif;
    font-weight: bold;
    color: black;
    font-size: 20px;
    text-align: center;
  }

  & p {
    font-family: Open Sans, sans-serif;
    color: #243568;
    font-size: 15px;
    text-align: center;
  }

  & .spinner-container,
  & .button-container {
    text-align: center;
  }
`;

function DetailPopupProcessing() {
  return (
    <StyledCard>
      <h1>Procesando información</h1>
      <div className="spinner-container">
        <Spinner />
      </div>
      <p>
        Este proceso puede demorar varios minutos y te avisaremos por mail
        cuando termine. Mientras tanto podes navegar otras secciones.
      </p>
      <div className="button-container">
        <LinkButton onClick={PopupboxManager.close}>Cancelar</LinkButton>
      </div>
    </StyledCard>
  );
}

export default DetailPopupProcessing;
