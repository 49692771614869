import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationHeader, Link, Tab, TabGroup } from 'increase-components';
import { withNamespaces } from 'react-i18next';

import logo from './logo.svg';
import Button from '../common/Button';

const NavBar = styled(TabGroup)`
  padding: 0 100px;
  height: 60px;
  background-color: #f2f2f2;
`;

const NavLink = styled(RouterLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 100%;
`;

const propTypes = {
  pathname: PropTypes.string,
};

function TopNav({ pathname, t }) {
  return (
    <React.Fragment>
      <ApplicationHeader logoUrl={logo}>
        <Link href="https://app.increasecard.com" style={{ fontSize: '15px' }}>
          Volver a IncreaseCard
        </Link>
      </ApplicationHeader>
      <NavBar>
        <RouterLink to="/">
          <Button style={{ pointerEvents: 'none' }}>
            {t('new_conciliation')}
          </Button>
        </RouterLink>
        <Tab active={pathname === '/reports'} as={NavLink} to="/reports">
          {t('my_reports')}
        </Tab>
        <Tab
          active={pathname === '/conciliations' ? 'true' : ''}
          as={NavLink}
          to="/conciliations"
        >
          {t('pending_operations.title')}
        </Tab>
        <Tab
          active={pathname === '/payments' ? 'true' : ''}
          as={NavLink}
          to="/payments"
        >
          {t('payments.title')}
        </Tab>
        <Tab active={pathname === '/profiles'} as={NavLink} to="/profiles">
          {t('see_profiles')}
        </Tab>
      </NavBar>
    </React.Fragment>
  );
}

TopNav.propTypes = propTypes;

const mapDispatchToProps = () => ({});

function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(TopNav));
