import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  border-radius: 6px;
  background-color: #3b86ff;
  color: white;
  font-size: 15px;
  line-height: 21px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
  padding: 6px 12px;
  border: none;
  outline: none;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      background-color: #e2e2e2;
    `} ${(props) =>
    props.size === 'large' &&
    css`
      padding: 22px 43px;
    `};
`;

function Button({ className, onClick, disabled, size, children }) {
  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      size={size}
      type="button"
    >
      {children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['normal', 'large']),
};

Button.defaultProps = {
  size: 'normal',
  disabled: false,
};

export default Button;
